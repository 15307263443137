import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { useAuth0 } from '@auth0/auth0-react';

import {
  FormFooter,
  CreditCardContainer,
  CreditCardNumberContainer,
  CreditCardExpiryDateContainer,
  FormHeader,
  FormMessage,
  FormBody,
  PoweredByContainer,
} from './../../Payment/StyledComponents';
import PoweredByStripeIcon from './../../../assets/img/powered-by-stripe-blurple.svg';
import { Text } from './../../../components';
import { useHistory } from 'react-router';
import api from 'utils/api';
import { updateUserData } from './../../../actions/user';

const PaymentForm = ({ organization, user, auth0User }) => {
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [errorText, setErrorText] = useState('');
  const [processing, setProcessing] = useState(false);
  const [saveCreditCard, setSaveCreditCard] = useState(true);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        //TODO adjust height, test different devices
        minHeight: '100vh',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FormHeader>
          <img height="75" width="75" src={organization?.logo} />
          <Text size={16} weight="600">
            {organization?.name}
          </Text>
        </FormHeader>
        <FormMessage>
          {
            <Text size={12}>
              Please enter your credit card details below. The default credit
              card will be used for future transaction payments
            </Text>
          }
        </FormMessage>{' '}
      </div>
      <FormBody style={{ padding: '16px 8px', marginBottom: 48 }}>
        <div style={{ marginLeft: 20 }}>
          <Text size={12}>Card Information</Text>
        </div>
        <CreditCardContainer>
          <CreditCardNumberContainer>
            <CardNumberElement
              {...createOptions()}
              style={{
                padding: 8,
                margin: 8,
                backgroundColor: 'cyan',
              }}
            />
          </CreditCardNumberContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <CreditCardExpiryDateContainer>
              <CardExpiryElement {...createOptions()} />
            </CreditCardExpiryDateContainer>
            <div
              style={{
                padding: 16,
                flex: 1,
              }}
            >
              <CardCvcElement {...createOptions()} />
            </div>
          </div>
        </CreditCardContainer>
        <div style={{ textAlign: 'center' }}>
          {errorText ? (
            <Text size={16} color="#eb1c26">
              {errorText}
            </Text>
          ) : (
            ''
          )}
        </div>
        <div
          style={{
            marginLeft: 16,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <input
            type="checkbox"
            defaultChecked={saveCreditCard}
            onChange={() => setSaveCreditCard(!saveCreditCard)}
            checked={saveCreditCard}
          />
          <Text
            style={{ marginLeft: 8 }}
            size="12"
            onClick={() => {
              setSaveCreditCard(!saveCreditCard);
            }}
          >
            Save this credit card as a default payment method
          </Text>
        </div>
        <FormFooter
          onClick={() => {
            setProcessing(true);
            var cardNumberElement = elements.getElement(CardNumberElement);

            stripe
              .createPaymentMethod({
                type: 'card',
                card: cardNumberElement,
              })
              .then(async function (result) {
                if (result.error) {
                  setErrorText(result.error.message);
                  setProcessing(false);
                } else {
                  let token = await getAccessTokenSilently();
                  const res = await api.patch(token, `v1/users/${user._id}`, {
                    stripe_default_payment_method: saveCreditCard
                      ? result.paymentMethod.id
                      : user?.stripe_default_payment_method,
                    stripe_payment_methods: [
                      ...user.stripe_payment_methods,
                      { id: result.paymentMethod.id },
                    ],
                  });
                  token = await getAccessTokenSilently();
                  dispatch(updateUserData(token));
                  setProcessing(false);
                  history.goBack();
                }
              });
          }}
          style={{ width: '90%' }}
          disabled={processing}
        >
          <Text style={{ color: 'white' }}>Add Credit Card</Text>
        </FormFooter>
        <div style={{ textAlign: 'end', marginRight: 16 }}>
          <Text size={10}>
            See{' '}
            {organization?.links?.privacy ? (
              <a
                style={{ color: '#5e72e4' }}
                href={organization?.links?.privacy ?? ''}
                target="_blank"
              >
                Terms and Conditions
              </a>
            ) : (
              'Terms and Conditions'
            )}
          </Text>
        </div>
      </FormBody>
      <PoweredByContainer>
        <img src={PoweredByStripeIcon} style={{ width: '70%' }} height="30" />
      </PoweredByContainer>
      <br />
    </div>
  );
};

const createOptions = () => {
  return {
    style: {
      base: {
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  user: state.user,
  status: state.status,
  auth0User: state.auth0User,
});

export default connect(mapStateToProps)(PaymentForm);
