import React from 'react';

const CcsIcon = ({ width, height, pathColor }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 26 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <path
      d="M17.5714 33.3848C18.0503 33.3848 18.446 33.7864 18.446 34.2725C18.446 34.7586 18.0503 35.1602 17.5714 35.1602C17.0924 35.1602 16.6967 34.7586 16.6967 34.2725C16.6967 33.7864 17.0924 33.3848 17.5714 33.3848Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.5714 31.0812C19.2998 31.0812 20.7158 32.5183 20.7158 34.2725C20.7158 36.0267 19.2998 37.4638 17.5714 37.4638C15.8429 37.4638 14.4269 36.0267 14.4269 34.2725C14.4269 32.5183 15.8429 31.0812 17.5714 31.0812ZM17.5714 32.307C18.6334 32.307 19.508 33.1946 19.508 34.2725C19.508 35.3504 18.6334 36.238 17.5714 36.238C16.5093 36.238 15.6347 35.3504 15.6347 34.2725C15.6347 33.1946 16.5093 32.307 17.5714 32.307Z"
      fill={pathColor}
    />
    <path
      d="M8.42951 33.3848C8.90847 33.3848 9.30413 33.7864 9.30413 34.2725C9.30413 34.7586 8.90847 35.1602 8.42951 35.1602C7.95055 35.1602 7.55489 34.7586 7.55489 34.2725C7.55489 33.7864 7.95055 33.3848 8.42951 33.3848Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.42951 31.0812C10.1579 31.0812 11.574 32.5183 11.574 34.2725C11.574 36.0267 10.1579 37.4638 8.42951 37.4638C6.7011 37.4638 5.28505 36.0267 5.28505 34.2725C5.28505 32.5183 6.7011 31.0812 8.42951 31.0812ZM8.42951 32.307C9.49155 32.307 10.3662 33.1946 10.3662 34.2725C10.3662 35.3504 9.49155 36.238 8.42951 36.238C7.36748 36.238 6.49286 35.3504 6.49286 34.2725C6.49286 33.1946 7.36748 32.307 8.42951 32.307Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.88433 28.5714C5.01784 28.8507 2.78614 31.3089 2.78614 34.2725C2.78614 37.4427 5.3267 40 8.42951 40H17.5714C20.695 40 23.2147 37.4216 23.2147 34.2725C23.2147 31.2897 20.9657 28.8496 18.1154 28.5714H7.88433ZM8.42951 38.267H17.5714C19.7371 38.267 21.5071 36.4705 21.5071 34.2725C21.5071 32.0745 19.7579 30.2781 17.5714 30.2781H8.42951C6.26379 30.2781 4.49373 32.0745 4.49373 34.2725C4.49373 36.4705 6.24297 38.267 8.42951 38.267Z"
      fill={pathColor}
    />
    <path
      d="M19.0322 16.0647C19.0322 15.7245 18.7667 15.4295 18.4126 15.4295C18.0806 15.4295 17.7929 15.7018 17.7929 16.0647C17.7929 16.405 18.0585 16.6999 18.4126 16.6999C18.7667 16.6772 19.0322 16.405 19.0322 16.0647Z"
      fill={pathColor}
    />
    <path
      d="M8.21016 16.0647C8.21016 15.7245 7.94459 15.4295 7.59049 15.4295C7.23639 15.4295 6.97082 15.7018 6.97082 16.0647C6.97082 16.405 7.23639 16.6999 7.59049 16.6999C7.94459 16.6999 8.21016 16.405 8.21016 16.0647Z"
      fill={pathColor}
    />
    <path
      d="M9.13966 8.64659C9.64867 8.64659 10.0692 9.07762 10.0692 9.59938C10.0692 10.1211 9.64867 10.5522 9.13966 10.5522C8.63065 10.5522 8.21016 10.1211 8.21016 9.59938C8.21016 9.07762 8.63065 8.64659 9.13966 8.64659Z"
      fill={pathColor}
    />
    <path
      d="M17.7708 9.59938C17.7708 9.07762 17.3503 8.64659 16.8413 8.64659C16.3322 8.64659 15.9118 9.07762 15.9118 9.59938C15.9118 10.1211 16.3322 10.5522 16.8413 10.5522C17.3724 10.5522 17.7708 10.1211 17.7708 9.59938Z"
      fill={pathColor}
    />
    <path
      d="M12.9905 17.6073C13.4995 17.6073 13.92 18.0384 13.92 18.5601C13.92 19.0819 13.4995 19.5129 12.9905 19.5129C12.4814 19.5129 12.061 19.0819 12.061 18.5601C12.061 18.0384 12.4814 17.6073 12.9905 17.6073Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.1765 13.3333C22.1765 18.5313 18.068 22.7451 13 22.7451C7.93198 22.7451 3.82353 18.5313 3.82353 13.3333C3.82353 8.13536 7.93198 3.92157 13 3.92157C18.068 3.92157 22.1765 8.13536 22.1765 13.3333ZM20.3822 16.0647C20.3822 14.9531 19.5191 14.0684 18.4347 14.0684C17.3503 14.0684 16.4872 14.9531 16.4872 16.0647C16.4872 17.1763 17.3503 18.0611 18.4347 18.0611C19.5191 18.0611 20.3822 17.1536 20.3822 16.0647ZM9.53802 16.0647C9.53802 14.9531 8.67491 14.0684 7.59049 14.0684C6.50607 14.0684 5.64296 14.9531 5.64296 16.0647C5.64296 17.1763 6.50607 18.0611 7.59049 18.0611C8.67491 18.0611 9.53802 17.1536 9.53802 16.0647ZM11.9724 9.59938C11.9724 7.98871 10.711 6.69564 9.13966 6.69564C7.56836 6.69564 6.30689 7.98871 6.30689 9.59938C6.30689 11.21 7.56836 12.5031 9.13966 12.5031C10.711 12.5031 11.9724 11.1874 11.9724 9.59938ZM19.674 9.59938C19.674 7.98871 18.4126 6.69564 16.8413 6.69564C15.27 6.69564 14.0085 7.98871 14.0085 9.59938C14.0085 11.21 15.27 12.5031 16.8413 12.5031C18.4126 12.5031 19.674 11.1874 19.674 9.59938ZM15.8232 18.5601C15.8232 16.9495 14.5618 15.6564 12.9905 15.6564C11.4192 15.6564 10.1577 16.9495 10.1577 18.5601C10.1577 20.1708 11.4192 21.4639 12.9905 21.4639C14.5618 21.4639 15.8232 20.1481 15.8232 18.5601Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26 13.3333C26 20.6972 20.179 26.6667 13 26.6667C5.82098 26.6667 0 20.6972 0 13.3333C0 5.96949 5.82098 0 13 0C20.179 0 26 5.96949 26 13.3333ZM24.5143 13.3333C24.5143 19.8556 19.3593 25.1429 13 25.1429C6.64074 25.1429 1.48571 19.8556 1.48571 13.3333C1.48571 6.81111 6.64074 1.52381 13 1.52381C19.3593 1.52381 24.5143 6.81111 24.5143 13.3333Z"
      fill={pathColor}
    />
  </svg>
);

export default CcsIcon;
