import ConnectorAvailable from './../../../assets/img/connector_available.png';
import ConnectorPluggedIn from './../../../assets/img/connector_plugged_in.png';
import ConnectorTimeout from './../../../assets/img/connector_timeout.png';

export const createRenderDataObject = (
  title,
  subTitle,
  image,
  imageAlt,
  startCharginButton,
  stopCharginButton,
  chargingTime,
  energyDelivered,
  resumeChargingButton = false
) => {
  return {
    title,
    subTitle,
    image,
    imageAlt,
    startCharginButton,
    stopCharginButton,
    chargingTime,
    energyDelivered,
    resumeChargingButton,
  };
};

export const RENDER_DATA_DEFAULT = createRenderDataObject(
  'Start Charging',
  'Please plug-in cable to your car to start charging',
  ConnectorAvailable,
  '',
  true,
  false,
  '',
  ''
);

export const RENDER_DATA_CONNECTION_LOST = createRenderDataObject(
  'Connection lost temporarily',
  'Please try refreshing the screen',
  ConnectorTimeout,
  '',
  false,
  false,
  '',
  ''
);

export const RENDER_DATA_PUBLIC_START_FAIL = createRenderDataObject(
  'Unable to start a charging transaction',
  'Please try again shortly',
  ConnectorTimeout,
  '',
  true,
  false,
  '',
  ''
);

export const RENDER_DATA_PUBLIC_STOP_FAIL = createRenderDataObject(
  'Unable to stop transaction remotely',
  'You can try again in a few minutes or unplug your cable to stop',
  ConnectorTimeout,
  '',
  true,
  false,
  '',
  ''
);

export const RENDER_DATA_PUBLIC_PAUSE_FAIL = createRenderDataObject(
  'Unable to pause transaction remotely',
  'You can try again in a few minutes or unplug your cable to stop',
  ConnectorTimeout,
  '',
  true,
  false,
  '',
  ''
);

export const RENDER_DATA_PAYMENT_INTENT_FAIL = createRenderDataObject(
  'Unable to cancel pre-auth payment',
  'Transaction start has been registered',
  ConnectorTimeout,
  '',
  true,
  false,
  '',
  ''
);
