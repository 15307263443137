import React from 'react';

const NavigationIcon = ({ width, height, pathColor }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <path
      d="M11.0844 0.781097L0.446533 5.23175L0.446534 5.81746L4.4847 7.38076L6.048 11.4189H6.63372L11.0844 0.781097Z"
      fill={pathColor}
    />
  </svg>
);

export default NavigationIcon;
