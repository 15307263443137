const filterChargestations = (chargestations) => {
  return chargestations
    .map((eachChargestation) => {
      let connectors = [];

      eachChargestation.connectors.map((eachConnector) => {
        if (eachConnector.rate) {
          connectors.push(eachConnector);
        }
      });

      eachChargestation.connectors = connectors;
      return eachChargestation;
    })
    .filter((eachChargestation) => eachChargestation.connectors.length > 0);
};

const getDefaultLocation = () => {};

export default { filterChargestations, getDefaultLocation };
