import { HomeButton } from 'components';
import React from 'react';

import SigninButton from './../AuthButtons/Signin';
import Text from './../Text/Text';

const Signin = ({ organization }) => {
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Text size={18}>Please sign in before viewing this page</Text>
      <br />
      <SigninButton
        organization={organization}
        style={{ width: '80%' }}
      ></SigninButton>
      <br />
      <HomeButton organization={organization} style={{ width: '80%' }} />
    </div>
  );
};

export default Signin;
