import React from 'react';

import Type2Icon from './../Icon/Type2Icon';
import J1772Icon from './../Icon/J1772Icon';
import CcsIcon from './../Icon/CcsIcon';
import Ccs2Icon from './../Icon/Ccs2Icon';
import ChademoIcon from './../Icon/ChademoIcon';

import './connectorIcon.css';

const ConnectorIcon = ({ type, color }) => {
  switch (type) {
    case 'Type 2':
      return (
        <Type2Icon
          className="connector-icon"
          stroke={color}
          pathColor={color}
          height="40"
          width="40"
        />
      );
    case 'J1772':
      return (
        <J1772Icon
          className="connector-icon"
          stroke={color}
          pathColor={color}
          height="40"
          width="40"
        />
      );
    case 'CCS1':
      return (
        <CcsIcon
          className="connector-icon"
          stroke={color}
          pathColor={color}
          height="40"
          width="40"
        />
      );
    case 'CCS2':
      return (
        <Ccs2Icon
          className="connector-icon"
          stroke={color}
          pathColor={color}
          height="40"
          width="40"
        />
      );
    case 'CHAdeMO':
      return (
        <ChademoIcon
          className="connector-icon"
          stroke={color}
          pathColor={color}
          height="40"
          width="40"
        />
      );
    default:
      return (
        <CcsIcon
          className="connector-icon"
          stroke={color}
          pathColor={color}
          height="40"
          width="40"
        />
      );
  }
};

export default ConnectorIcon;
