import { INITIALIZE_ACTION } from './../constants';

export const getOrganization = () => {
  const request = window.fetch(
    `${process.env.REACT_APP_API_URL}/v1/public/organization`
  );

  return (dispatch) => {
    request.then((response) => {
      if (response.status === 200) {
        response.json().then((organization) => {
          dispatch({
            type: INITIALIZE_ACTION,
            payload: { ...organization.result },
          });
        });
      }
    });
  };
};
