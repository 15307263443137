import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';

import { Loading, NotFound, TransactionsStats, Root, Text } from 'components';
import api from 'utils/api';

const dateFormat = 'hh:mm A DD/MM/YY';

const TransactionDetail = ({ organization, user, auth0User }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [transaction, setTransaction] = useState({});

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const token = await getAccessTokenSilently();
    const request = await api.get(
      token,
      `v1/transactions/${id}?include_chargestation=true`
    );
    setTransaction(request.result);
    setLoading(false);
  };

  if (loading) {
    return <Loading></Loading>;
  } else if (!transaction || Object.keys(transaction).length === 0) {
    return (
      <Root
        organization={organization}
        user={user}
        auth0User={auth0User}
        title="Transaction Details"
        showSupportChat={false}
      >
        <NotFound text="Transaction Not Found" />
      </Root>
    );
  } else {
    const startTime =
      `${moment(transaction?.metrics?.chargingStart)
        .utc()
        .local()
        .format(dateFormat)} ${moment.tz(moment.tz.guess()).zoneAbbr()}` ?? '-';

    const stopTime =
      transaction.metrics &&
      transaction.metrics.chargingStart &&
      transaction.metrics.timeSpentCharging
        ? `${moment(
            new Date(
              new Date(transaction.metrics.chargingStart).getTime() +
                transaction.metrics.timeSpentCharging * 1000
            )
          ).format(dateFormat)} ${moment.tz(moment.tz.guess()).zoneAbbr()}`
        : '-';

    return (
      <Root
        organization={organization}
        user={user}
        auth0User={auth0User}
        title="Transaction Details"
        showSupportChat={false}
      >
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <TransactionsStats data={[transaction]} />
          <div
            style={{
              backgroundColor: 'white',
              margin: 16,
              padding: 16,
              borderRadius: 8,
            }}
          >
            <div>
              <Text size={20} weight={600}>
                {' '}
                Session Details{' '}
              </Text>
            </div>
            <br />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 8,
              }}
            >
              <Text size={14}> Start Time </Text>
              <Text size={16}>{startTime}</Text>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Text size={14}> End Time </Text>
              <Text size={16}>{stopTime}</Text>
            </div>
          </div>
        </div>
      </Root>
    );
  }
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  user: state.user,
  Auth0User: state.Auth0User,
});
export default connect(mapStateToProps)(TransactionDetail);
