import React from 'react';
import Text from './../Text/Text';

const NotFound = ({ text = 'Page Not Found' }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      }}
    >
      <Text size={30} style={{ textAlign: 'center' }}>
        {text}
      </Text>
    </div>
  );
};

export default NotFound;
