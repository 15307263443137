import React from 'react';

const ChevronForwardOutline = ({ width, height, style, pathColor }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="ionicon"
    viewBox="0 0 512 512"
    width={width}
    height={height}
    style={style}
  >
    <title>Chevron Forward</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="48"
      d="M184 112l144 144-144 144"
    />
  </svg>
);

export default ChevronForwardOutline;
