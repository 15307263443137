import React from 'react';
import styled from 'styled-components';
import { FONT_DEFAULT_COLOR } from './../../theme';

const Text = (props) => {
  return <StyledSpan {...props}>{props.children}</StyledSpan>;
};

const StyledSpan = styled.span`
  font-family: 'Proxima Nova';
  font-weight: ${(props) => (props.weight ? props.weight : 500)};
  font-size: ${(props) => (props.size ? `${props.size}px` : '14px')};
  color: ${(props) => (props.color ? props.color : FONT_DEFAULT_COLOR)};
  opacity: ${(props) => (props.opacity ? props.opacity : 1)};
  letter-spacing: 0.02em;
`;

export default Text;
