import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import { PRIMARY_COLOR } from 'theme';
import Text from '../Text/Text';
import ChevronForwardOutlineIcon from './../Icon/ChevronForwardOutline';
import CloseOutlineIcon from './../Icon/CloseOutline';
import { useInterval } from 'hooks';
import api from 'utils/api';

const DetectLowBalance = ({ organization, user, redirect = false }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const history = useHistory();
  const organizationColor = organization?.theme?.colors?.primary
    ? organization?.theme?.colors?.primary
    : PRIMARY_COLOR;

  const [showDialog, setShowDialog] = useState(false);
  const [renderData, setRenderData] = useState({
    title: 'Your wallet balance is low. Please top up soon',
  });

  useEffect(() => {
    init();
  }, []);

  useInterval(() => {
    setShowDialog(false);
  }, 8000);

  const init = async () => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      const request = await api.get(token, 'v1/users/current');
      const user = request.result;
      setShowDialog(
        parseFloat(-user.balance / 100) <
          (organization?.driver_application?.wallet?.minimum_balance
            ? organization?.driver_application?.wallet?.minimum_balance
            : organization.stripe_reserve_amount)
      );
    }
  };

  const goToWallet = () => {
    history.push(`/wallet`);
  };

  if (!showDialog) {
    return null;
  } else {
    return (
      <Container color={organizationColor} onClick={goToWallet}>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <div>
            <Text color="white" size={16}>
              {renderData.title}
            </Text>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {/* <div style={{ marginTop: -8, marginLeft: 16 }}>
            <CloseOutlineIcon
              height="22"
              style={{ color: 'white' }}
              onClick={(e) => {
                setShowDialog(false);
                e.stopPropagation();
              }}
            />
          </div> */}
          <ChevronForwardOutlineIcon
            height="24"
            style={{
              color: 'white',
              flex: 1,
              display: 'flex',
              alignItems: 'flex-end',
            }}
          />
        </div>
      </Container>
    );
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  z-index: 9999;
  width: 96%;
  background-color: ${(props) => props.color};
  margin: 2%;
  padding: 16px;
  border-radius: 8px;
`;

const Button = styled.button`
  flex: 1;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  border-radius: 4px;
  margin: 4px;
  padding: 8px;
  color: white;
`;

const mapStateToProps = (state) => ({
  organization: state.organization,
});

export default connect(mapStateToProps)(DetectLowBalance);
