import React from 'react';
import ConnectorDoublePin from '../Icon/ConnectorDoublePin';
import ConnectorSinglePin from '../Icon/ConnectorSinglePin';
import Text from '../Text/Text';
import { CONNECTOR_STATUS } from './../../constants';
import {
  DEFAULT_GREY,
  RING_AVAILABLE_COLOR,
  RING_UNAVAILABLE_COLOR,
  RING_RESERVED_COLOR,
  ACCENT_COLOR,
  PRIMARY_COLOR,
} from './../../theme';

const getRingColorFromStatus = (connector) => {
  if (
    connector.status === CONNECTOR_STATUS.AVAILABLE
    // connector.status === CONNECTOR_STATUS.UNAVAILABLE ||
    // connector.status === CONNECTOR_STATUS.FAULTED
  ) {
    return RING_AVAILABLE_COLOR;
  } else if (connector.status === CONNECTOR_STATUS.RESERVED) {
    return RING_RESERVED_COLOR;
  } else {
    return RING_UNAVAILABLE_COLOR;
  }
};

const getRingBackgroundColor = (connectors) => {
  if (connectors.length === 1) {
    if (
      connectors[0].status === CONNECTOR_STATUS.UNAVAILABLE ||
      connectors[0].status === CONNECTOR_STATUS.FAULTED
    ) {
      return DEFAULT_GREY;
    } else {
      if (connectors[0].power > 43) {
        return PRIMARY_COLOR;
      } else {
        return ACCENT_COLOR;
      }
    }
  } else if (connectors.length === 2) {
    if (
      connectors[0].status === CONNECTOR_STATUS.UNAVAILABLE ||
      connectors[0].status === CONNECTOR_STATUS.FAULTED ||
      connectors[1].status === CONNECTOR_STATUS.UNAVAILABLE ||
      connectors[1].status === CONNECTOR_STATUS.FAULTED
    ) {
      return DEFAULT_GREY;
    } else {
      if (Math.max(connectors[0].power, connectors[1].power) > 43) {
        return PRIMARY_COLOR;
      } else {
        return ACCENT_COLOR;
      }
    }
  } else {
    return DEFAULT_GREY;
  }
};

const ChargestationMarker = ({
  organization,
  chargestation,
  onClick,
  mapZoomLevel,
  onClickZoom,
}) => {
  const organizationColor = organization?.theme?.colors?.primary
    ? organization?.theme?.colors?.primary
    : PRIMARY_COLOR;
  const connectors = chargestation.connectors.filter(
    (eachConnector) => eachConnector.rate
  );
  // if (mapZoomLevel < 17 && chargestation?.nearByChargestations?.length > 1) {
  //   if (chargestation.show) {
  //     const ringColor =
  //       chargestation.nearByChargestations.filter(
  //         (eachNearByChargestation) =>
  //           eachNearByChargestation.online &&
  //           (chargestation.open_for_business ?? true) &&
  //           eachNearByChargestation.connectors.filter(
  //             (eachConnector) =>
  //               eachConnector.status === CONNECTOR_STATUS.AVAILABLE
  //           ).length > 0
  //       ).length > 0
  //         ? RING_AVAILABLE_COLOR
  //         : RING_UNAVAILABLE_COLOR;

  //     const ringBackgroundColor =
  //       chargestation.nearByChargestations.filter(
  //         (eachNearByChargestation) =>
  //           eachNearByChargestation.online &&
  //           (chargestation.open_for_business ?? true) &&
  //           eachNearByChargestation.connectors.filter(
  //             (eachConnector) =>
  //               eachConnector.status !== CONNECTOR_STATUS.UNAVAILABLE &&
  //               eachConnector.status !== CONNECTOR_STATUS.FAULTED
  //           ).length > 0
  //       ).length > 0
  //         ? PRIMARY_COLOR
  //         : DEFAULT_GREY;

  //     return (
  //       <div
  //         style={{
  //           height: 40,
  //           width: 40,
  //           backgroundColor: ringBackgroundColor,
  //           padding: 6,
  //           borderRadius: 32,
  //           display: 'flex',
  //           cursor: 'pointer',
  //         }}
  //       >
  //         <div
  //           style={{
  //             flex: 1,
  //             display: 'flex',
  //             justifyContent: 'center',
  //             alignItems: 'center',
  //             backgroundColor: ringBackgroundColor
  //               ? ringBackgroundColor
  //               : DEFAULT_GREY,
  //             border: `3px solid ${ringColor}`,
  //             borderRadius: 32,
  //           }}
  //           onClick={() => {
  //             onClickZoom(chargestation);
  //           }}
  //         >
  //           <Text size={14} color="#FFF">
  //             {chargestation.nearByChargestations.length}
  //           </Text>
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return null;
  //   }
  // } else {

  if (connectors.length === 1) {
    return (
      <div style={{ cursor: 'pointer' }}>
        <ConnectorSinglePin
          backgroundColor={
            chargestation.online && (chargestation.open_for_business ?? true)
              ? getRingBackgroundColor(connectors)
              : DEFAULT_GREY
          }
          ringColor={getRingColorFromStatus(connectors[0])}
          height={40}
          width={40}
          onClick={onClick}
        />
      </div>
    );
  } else if (connectors.length === 2) {
    return (
      <div style={{ cursor: 'pointer' }}>
        <ConnectorDoublePin
          backgroundColor={
            chargestation.online && (chargestation.open_for_business ?? true)
              ? getRingBackgroundColor(connectors)
              : DEFAULT_GREY
          }
          leftRingColor={getRingColorFromStatus(connectors[0])}
          rightRingColor={getRingColorFromStatus(connectors[1])}
          height={40}
          width={40}
          onClick={onClick}
        />
      </div>
    );
  } else {
    return null;
  }
  // }
};

export default ChargestationMarker;
