import React, { useEffect, useState } from 'react';
import {
  PaymentRequestButtonElement,
  useStripe,
} from '@stripe/react-stripe-js';
import { useAuth0 } from '@auth0/auth0-react';

import { ToastContainer, toast } from 'react-toastify';
import api from 'utils/api';

const ApplePayButton = ({
  selectedTopUpOption,
  organization,
  onClick,
  style = {},
}) => {
  const stripe = useStripe();
  const { getAccessTokenSilently } = useAuth0();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    setPaymentRequest(null);
  }, [selectedTopUpOption]);

  useEffect(() => {
    if (stripe && selectedTopUpOption.price) {
      const pr = stripe.paymentRequest({
        country: organization?.stripe_country?.toUpperCase() ?? 'NL',
        currency: organization?.stripe_currency?.toLowerCase(),
        total: {
          label: '',
          amount: selectedTopUpOption.price,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        } else {
          console.log('ERROR');
        }
      });
    }
  }, [stripe, selectedTopUpOption]);

  useEffect(() => {
    paymentRequest &&
      paymentRequest.on('paymentmethod', async (ev) => {
        let token = await getAccessTokenSilently();
        const response = await api.post(token, 'v1/wallet/credit', {
          product_id: selectedTopUpOption._id,
          type: 'payment_request',
        });
        setClientSecret(response.result.client_secret);

        setPaymentProcessing(true);
        // Confirm the PaymentIntent without handling potential next actions (yet).
        const { paymentIntent, error: confirmError } =
          await stripe.confirmCardPayment(
            response.result.client_secret,
            { payment_method: ev.paymentMethod.id },
            { handleActions: false }
          );
        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete('fail');
          onClick(confirmError.message);
          // setErrorText(confirmError.message);
          setPaymentProcessing(false);
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete('success');
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11" instead
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent.status === 'requires_action') {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardPayment(clientSecret);
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
              // console.log('Please select a different payment method');
              onClick('Please select a different payment method');
              // setErrorText('Please select a different payment method');
              setPaymentProcessing(false);
            } else {
              // console.log('success');
              setPaymentProcessing(false);
              onClick();
            }
          } else {
            // The payment has succeeded.
            // console.log('Success');
            onClick();
            setPaymentProcessing(false);
          }
        }
      });
  }, [paymentRequest]);

  return (
    <>
      {paymentRequest ? (
        <div style={{ margin: '16px 0', ...style }}>
          <PaymentRequestButtonElement
            options={{
              paymentRequest,
              style: {
                paymentRequestButton: {
                  theme: 'dark',
                  height: '60px',
                  borderRadius: '8px',
                },
              },
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export default ApplePayButton;
