import React from 'react';

const Ccs2Icon = ({ width, height, pathColor }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.59267 7.57023C9.49817 7.57023 10.2322 6.84765 10.2322 5.9563C10.2322 5.06495 9.49817 4.34237 8.59267 4.34237C7.68718 4.34237 6.95312 5.06495 6.95312 5.9563C6.95312 6.84765 7.68718 7.57023 8.59267 7.57023ZM8.59267 6.70737C8.17128 6.70737 7.82968 6.3711 7.82968 5.9563C7.82968 5.54149 8.17128 5.20523 8.59267 5.20523C9.01406 5.20523 9.35567 5.54149 9.35567 5.9563C9.35567 6.3711 9.01406 6.70737 8.59267 6.70737Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.046 5.9563C17.046 6.84765 16.312 7.57023 15.4065 7.57023C14.501 7.57023 13.7669 6.84765 13.7669 5.9563C13.7669 5.06495 14.501 4.34237 15.4065 4.34237C16.312 4.34237 17.046 5.06495 17.046 5.9563ZM14.6435 5.9563C14.6435 6.3711 14.9851 6.70737 15.4065 6.70737C15.8279 6.70737 16.1695 6.3711 16.1695 5.9563C16.1695 5.54149 15.8279 5.20523 15.4065 5.20523C14.9851 5.20523 14.6435 5.54149 14.6435 5.9563Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.16415 9.6102C8.16415 10.8972 7.10431 11.9404 5.79692 11.9404C4.48953 11.9404 3.42969 10.8972 3.42969 9.6102C3.42969 8.32324 4.48953 7.27995 5.79692 7.27995C7.10431 7.27995 8.16415 8.32324 8.16415 9.6102ZM4.60483 9.6102C4.60483 10.2583 5.13855 10.7837 5.79692 10.7837C6.45529 10.7837 6.98901 10.2583 6.98901 9.6102C6.98901 8.96211 6.45529 8.43673 5.79692 8.43673C5.13855 8.43673 4.60483 8.96211 4.60483 9.6102Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.5704 9.6102C20.5704 10.8972 19.5106 11.9404 18.2032 11.9404C16.8958 11.9404 15.8359 10.8972 15.8359 9.6102C15.8359 8.32324 16.8958 7.27995 18.2032 7.27995C19.5106 7.27995 20.5704 8.32324 20.5704 9.6102ZM17.0111 9.6102C17.0111 10.2583 17.5448 10.7837 18.2032 10.7837C18.8615 10.7837 19.3953 10.2583 19.3953 9.6102C19.3953 8.96211 18.8615 8.43673 18.2032 8.43673C17.5448 8.43673 17.0111 8.96211 17.0111 9.6102Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.7345 14.4709C11.7345 15.7578 10.6746 16.8011 9.36723 16.8011C8.05985 16.8011 7 15.7578 7 14.4709C7 13.1839 8.05985 12.1406 9.36723 12.1406C10.6746 12.1406 11.7345 13.1839 11.7345 14.4709ZM8.17514 14.4709C8.17514 15.119 8.70886 15.6443 9.36723 15.6443C10.0256 15.6443 10.5593 15.119 10.5593 14.4709C10.5593 13.8228 10.0256 13.2974 9.36723 13.2974C8.70886 13.2974 8.17514 13.8228 8.17514 14.4709Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.0678 14.4709C17.0678 15.7578 16.008 16.8011 14.7006 16.8011C13.3932 16.8011 12.3333 15.7578 12.3333 14.4709C12.3333 13.1839 13.3932 12.1406 14.7006 12.1406C16.008 12.1406 17.0678 13.1839 17.0678 14.4709ZM13.5085 14.4709C13.5085 15.119 14.0422 15.6443 14.7006 15.6443C15.3589 15.6443 15.8927 15.119 15.8927 14.4709C15.8927 13.8228 15.3589 13.2974 14.7006 13.2974C14.0422 13.2974 13.5085 13.8228 13.5085 14.4709Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0016 12.3341C13.5299 12.3341 14.7688 11.1146 14.7688 9.61013C14.7688 8.10571 13.5299 6.88614 12.0016 6.88614C10.4733 6.88614 9.23438 8.10571 9.23438 9.61013C9.23438 11.1146 10.4733 12.3341 12.0016 12.3341ZM12.0016 11.0216C12.7935 11.0216 13.4355 10.3897 13.4355 9.61013C13.4355 8.83059 12.7935 8.19864 12.0016 8.19864C11.2097 8.19864 10.5677 8.83059 10.5677 9.61013C10.5677 10.3897 11.2097 11.0216 12.0016 11.0216Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24 9.51562C24 15.8583 18.6274 21 12 21C5.37258 21 0 15.8583 0 9.51562C0 3.17298 1.33333 0 12 0C22.6667 0 24 3.17298 24 9.51562ZM22 9.51562C22 14.719 17.5763 19.0312 12 19.0312C6.42367 19.0312 2 14.719 2 9.51562C2 6.32111 2.38772 4.74868 3.39011 3.79477C4.44366 2.79216 6.70968 1.96875 12 1.96875C17.2903 1.96875 19.5563 2.79216 20.6099 3.79477C21.6123 4.74868 22 6.32111 22 9.51562Z"
      fill={pathColor}
    />
    <path
      d="M8.01937 28.9861C8.01937 28.4757 7.59327 28.0541 7.07747 28.0541C6.56167 28.0541 6.13558 28.4757 6.13558 28.9861C6.13558 29.4965 6.56167 29.9182 7.07747 29.9182C7.59327 29.9182 8.01937 29.4965 8.01937 28.9861Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.07747 25.6352C8.93884 25.6352 10.4638 27.1442 10.4638 28.9861C10.4638 30.828 8.93884 32.337 7.07747 32.337C5.21611 32.337 3.69113 30.828 3.69113 28.9861C3.69113 27.1442 5.21611 25.6352 7.07747 25.6352ZM7.07747 26.9223C8.2212 26.9223 9.1631 27.8544 9.1631 28.9861C9.1631 30.1179 8.2212 31.0499 7.07747 31.0499C5.93374 31.0499 4.99185 30.1179 4.99185 28.9861C4.99185 27.8544 5.93374 26.9223 7.07747 26.9223Z"
      fill={pathColor}
    />
    <path
      d="M16.9225 28.0541C17.4383 28.0541 17.8644 28.4757 17.8644 28.9861C17.8644 29.4965 17.4383 29.9182 16.9225 29.9182C16.4067 29.9182 15.9806 29.4965 15.9806 28.9861C15.9806 28.4757 16.4067 28.0541 16.9225 28.0541Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.9225 25.6352C18.7839 25.6352 20.3089 27.1442 20.3089 28.9861C20.3089 30.828 18.7839 32.337 16.9225 32.337C15.0612 32.337 13.5362 30.828 13.5362 28.9861C13.5362 27.1442 15.0612 25.6352 16.9225 25.6352ZM16.9225 26.9223C18.0663 26.9223 19.0082 27.8544 19.0082 28.9861C19.0082 30.1179 18.0663 31.0499 16.9225 31.0499C15.7788 31.0499 14.8369 30.1179 14.8369 28.9861C14.8369 27.8544 15.7788 26.9223 16.9225 26.9223Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 28.9861C1 25.8743 3.40336 23.2932 6.49035 23H17.5084C20.578 23.292 23 25.8542 23 28.9861C23 32.2927 20.2864 35 16.9225 35H7.07747C3.73598 35 1 32.3148 1 28.9861ZM21.1611 28.9861C21.1611 31.294 19.2548 33.1803 16.9225 33.1803H7.07747C4.72273 33.1803 2.83894 31.294 2.83894 28.9861C2.83894 26.6782 4.74516 24.792 7.07747 24.792H16.9225C19.2773 24.792 21.1611 26.6782 21.1611 28.9861Z"
      fill={pathColor}
    />
  </svg>
);

export default Ccs2Icon;
