import React from 'react';
import Text from './../Text/Text';

const Button = ({
  id,
  buttonText,
  backgroundColor,
  onClick,
  processing,
  fontColor,
  fontStyle,
  containerStyle,
  show = true,
}) => {
  const _backgroundColor = processing
    ? 'rgba(128,128,128,0.5)'
    : backgroundColor;

  if (!show) return null;

  return (
    <div
      id={id}
      style={{
        backgroundColor: _backgroundColor,
        textAlign: 'center',
        padding: 8,
        cursor: 'pointer',
        borderRadius: 8,
        ...containerStyle,
      }}
      onClick={!processing ? onClick : () => {}}
    >
      <Text size={16} color={fontColor} style={fontStyle}>
        {buttonText}
      </Text>
    </div>
  );
};

export default Button;
