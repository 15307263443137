import { UPDATE_AUTH0_USER_DATA_ACTION } from './../constants';

export const updateAuth0UserData = (payload) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_AUTH0_USER_DATA_ACTION,
      payload,
    });
  };
};
