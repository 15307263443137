import {INITIALIZE_ACTION as INITIALIZE} from './../constants';

const initialState = {
  loading: true
};

const organization = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
          ...state,
          ...action.payload,
          loading: false
      }
    default:
      return state;
  }
}

export default organization;
