import React from 'react';
import { connect } from 'react-redux';

import { Root, SigninPage } from 'components';
import './unauthorized.css';

const Unauthorized = ({ organization, user, auth0User, title = '' }) => {
  return (
    <Root
      organization={organization}
      user={user}
      auth0User={auth0User}
      title={title}
      showSupportChat={true}
    >
      <SigninPage organization={organization} />
    </Root>
  );
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  user: state.user,
  Auth0User: state.Auth0User,
});
export default connect(mapStateToProps)(Unauthorized);
