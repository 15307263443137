import React from 'react';
import { Button } from 'reactstrap';

import { PRIMARY_COLOR } from 'theme';

const ResumeButton = ({
  show,
  resumeCommandLoading,
  organizationColor,
  onClick,
}) => {
  const backgroundColor = organizationColor ? organizationColor : PRIMARY_COLOR;

  if (!show) return null;

  return (
    <div style={{ padding: 16 }}>
      <Button
        id="resume-charging"
        className="action-button"
        style={{
          color: 'white',
          backgroundColor,
          borderColor: backgroundColor,
          opacity: resumeCommandLoading ? 0.4 : 1,
        }}
        onClick={onClick}
        disabled={resumeCommandLoading}
      >
        {resumeCommandLoading ? 'Resuming Session' : 'Resume Charging'}
      </Button>
    </div>
  );
};

export default ResumeButton;
