import api from 'utils/api';
import { getQueryString } from 'utils/helper';
import { UPDATE_HISTORY_DATA_ACTION } from './../constants';

export const updateHistoryData = (token, q) => {
  let query = {
    include_chargestation: true,
    paginate_limit: 100,
    ...q,
  };

  let qs = `?${getQueryString(query)}`;

  const request = api.get(token, `v1/transactions`, qs);

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: UPDATE_HISTORY_DATA_ACTION,
        payload: response.result,
      })
    );
};
