import React, { useEffect, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';

import { getOrganizationColor } from 'utils/helper';
import Text from 'components/Text/Text';
import ChargestationMarker from 'components/Maps/ChargestationMarker';

const GoogleMapWithChargestations = ({
  defaultCurrentLocation,
  defaultMapZoomLevel,
  defaultBounds,
  setSelectedChargeStation,
  clusterData,
  organization,
}) => {
  const mapRef = useRef();
  const [currentLocation, setCurrentLocation] = useState(
    defaultCurrentLocation
  );
  const [mapZoomLevel, setMapZoomLevel] = useState(defaultMapZoomLevel);
  const [bounds, setBounds] = useState(defaultBounds);

  const { clusters, supercluster } = useSupercluster({
    points: clusterData,
    bounds,
    zoom: mapZoomLevel,
    options: {
      radius: 50,
      maxZoom: 20,
      map: (props) => {
        return { count: props.connectors.length };
      },
      reduce: (acc, props) => {
        acc.count += props.count;
        return acc;
      },
    },
  });

  useEffect(() => {
    setCurrentLocation(defaultCurrentLocation);
    setMapZoomLevel(defaultMapZoomLevel);
  }, [defaultCurrentLocation, defaultMapZoomLevel]);

  const handleApiLoaded = (map, maps) => {
    mapRef.current = map;
  };

  const onChange = ({ center, zoom, bounds, marginBounds, size }) => {
    setCurrentLocation(center);
    setMapZoomLevel(zoom);
    setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
  };

  return (
    <GoogleMapReact
      onGoogleApiLoaded={({ map, maps }) => {
        handleApiLoaded(map, maps);
      }}
      yesIWantToUseGoogleMapApiInternals
      bootstrapURLKeys={{
        key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      }}
      onChange={onChange}
      center={currentLocation}
      defaultZoom={13}
      zoom={mapZoomLevel}
      options={{
        zoomControl: false,
        fullscreenControl: false,
      }}
      onClick={() => {
        setSelectedChargeStation({});
      }}
    >
      {clusters
        .filter((each) => !each.properties.isCluster)
        .map((cluster, index) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { isCluster, point_count, count } = cluster.properties;
          if (cluster?.properties?.cluster) {
            return (
              <div key={`cluster-${cluster.id}`} lat={latitude} lng={longitude}>
                <div
                  className="cluster-marker"
                  style={{
                    width: `${30 + (count / clusterData.length) * 20}px`,
                    height: `${30 + (count / clusterData.length) * 20}px`,
                    background: getOrganizationColor(organization),
                  }}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20
                    );
                    mapRef.current.setZoom(expansionZoom);
                    mapRef.current.panTo({
                      lat: latitude,
                      lng: longitude,
                    });
                    // onChange({
                    //   center: {
                    //     lat: latitude,
                    //     lng: longitude,
                    //   },
                    //   zoom: expansionZoom,
                    //   bounds: {
                    //     nw: {
                    //       lng: bounds[0],
                    //       lat: bounds[3],
                    //     },
                    //     se: {
                    //       lng: bounds[2],
                    //       lat: bounds[1],
                    //     },
                    //   },
                    // });
                  }}
                >
                  <Text size={14} color="white">
                    {' '}
                    {count}{' '}
                  </Text>
                </div>
              </div>
            );
          }

          return (
            <ChargestationMarker
              lat={cluster.properties.geoLocation.coordinates[1]}
              lng={cluster.properties.geoLocation.coordinates[0]}
              chargestation={cluster.properties}
              organization={organization}
              mapZoomLevel={mapZoomLevel}
              onClick={(e) => {
                setSelectedChargeStation(cluster.properties);
                e.stopPropagation();
              }}
              onClickZoom={(cs) => {
                onChange({
                  center: {
                    lat: cs.geoLocation.coordinates[1],
                    lng: cs.geoLocation.coordinates[0],
                  },
                  zoom: 18,
                  bounds: {
                    nw: {
                      lng: bounds[0],
                      lat: bounds[3],
                    },
                    se: {
                      lng: bounds[2],
                      lat: bounds[1],
                    },
                  },
                });
              }}
            />
          );
        })}
    </GoogleMapReact>
  );
};

export default GoogleMapWithChargestations;
