import React from 'react';
import { Button } from 'reactstrap';

const CancelButton = ({
  show,
  startCommandLoading,
  cancelCommandLoading,
  onClick,
}) => {
  if (!show) return null;

  return (
    <div style={{ padding: 16 }}>
      <Button
        id="cancel-charging"
        className="action-button"
        style={{
          color: 'black',
          backgroundColor: '#DDD',
          borderColor: '#DDD',
          opacity: startCommandLoading || cancelCommandLoading ? 0.4 : 1,
        }}
        onClick={onClick}
        disabled={startCommandLoading || cancelCommandLoading}
      >
        {cancelCommandLoading ? 'Cancelling Session' : 'Cancel'}
      </Button>
    </div>
  );
};

export default CancelButton;
