import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { PRIMARY_COLOR } from 'theme';

import Text from './../Text/Text';

const Signin = ({ organization, style, show = true }) => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  const organizationColor =
    organization?.theme?.colors?.primary ?? PRIMARY_COLOR;
  const organizationId = organization?.id;

  if (!show) {
    return null;
  }

  if (!isLoading && !isAuthenticated) {
    return (
      <div
        id="signin-button"
        style={{
          padding: '16px 8px',
          textAlign: 'center',
          backgroundColor: 'white',
          ...style,
        }}
        onClick={() => {
          if (window.location.pathname.indexOf('payment') === -1) {
            localStorage.setItem(
              'redirectAfterAuth0',
              window.location.pathname
            );
          } else {
            localStorage.setItem(
              'redirectAfterAuth0',
              localStorage.getItem('endpoint')
            );
            localStorage.setItem('clear_cookies', true);
          }

          loginWithRedirect({
            organizationId,
            organizationPrivacyLink: organization?.links?.privacy,
            organizationTermsLink: organization?.links?.terms,
            redirectUri: `${window.location.protocol}//${window.location.host}`,
            title: 'Log in',
          });
        }}
      >
        <Text size={16} weight={600} color={organizationColor}>
          Sign In
        </Text>
      </div>
    );
  }

  return null;
};

export default Signin;
