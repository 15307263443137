import React from 'react';

const BackArrow = ({ width, height, pathColor, style, onClick }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    style={style}
    onClick={onClick}
  >
    <path
      d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z"
      fill={pathColor}
    />
  </svg>
);

export default BackArrow;
