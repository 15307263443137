import React from 'react';
import { IoTimeOutline, IoFlash } from 'react-icons/io5';
import { PRIMARY_COLOR } from 'theme';
import { Text } from 'components';

const DetailCard = ({ id, show, title, detail, organizationColor }) => {
  if (!show) return null;

  return (
    <div
      className="card-container"
      style={{
        backgroundColor: 'white',
      }}
      id={id}
    >
      <div style={{ paddingRight: 8 }}>
        {title === 'Charging time' ? (
          <IoTimeOutline
            color={organizationColor ? organizationColor : PRIMARY_COLOR}
          />
        ) : (
          <IoFlash
            color={organizationColor ? organizationColor : PRIMARY_COLOR}
          />
        )}
      </div>
      <div style={{ flex: 1 }}>
        <Text size={14} weight={400}>
          {title}
        </Text>
      </div>

      <div>
        <Text size={16} weight={600}>
          {detail}
        </Text>
      </div>
    </div>
  );
};

export default DetailCard;
