import React from 'react';
import { Button } from 'reactstrap';

import { PRIMARY_COLOR } from 'theme';

const StartButton = ({
  show,
  startCommandLoading,
  cancelCommandLoading,
  token,
  organizationColor,
  onClick,
  validConnectorStatus,
}) => {
  const backgroundColor = organizationColor ? organizationColor : PRIMARY_COLOR;

  if (!show) return null;

  return (
    <div style={{ padding: 16 }}>
      <Button
        id="start-charging"
        className="action-button"
        style={{
          color: 'white',
          backgroundColor,
          borderColor: backgroundColor,
          opacity:
            startCommandLoading || cancelCommandLoading || token ? 0.4 : 1,
        }}
        onClick={onClick}
        disabled={
          startCommandLoading ||
          cancelCommandLoading ||
          token ||
          !validConnectorStatus
        }
      >
        {validConnectorStatus
          ? startCommandLoading || token
            ? 'Connecting...'
            : 'Start Charging'
          : 'Awaiting Driver Action'}
        {}
      </Button>
    </div>
  );
};

export default StartButton;
