import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { PRIMARY_COLOR } from 'theme';
import { useHistory } from 'react-router-dom';

import Text from './../Text/Text';

const Home = ({ organization, style, show = true }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const history = useHistory();
  const organizationColor =
    organization?.theme?.colors?.primary ?? PRIMARY_COLOR;

  if (!show) {
    return null;
  }

  if (!isLoading && !isAuthenticated) {
    return (
      <div
        id="home-button"
        style={{
          padding: '16px 8px',
          textAlign: 'center',
          backgroundColor: 'white',
          ...style,
        }}
        onClick={() => {
          history.push('/');
        }}
      >
        <Text size={16} weight={600} color={organizationColor}>
          Home
        </Text>
      </div>
    );
  }

  return null;
};

export default Home;
