import styled from 'styled-components';

export const FormHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const FormMessage = styled.div`
  display: flex;
  margin: 8px 24px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  border-color: #ccc;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  padding: 4px;
  margin: 16px;
`;

export const FormFooter = styled.button`
  background-color: ${(props) =>
    props.disabled ? 'rgb(0, 116, 212, 0.5)' : 'rgb(0, 116, 212)'};
  margin: 16px;
  padding: 8px;
  border-radius: 4px;
  text-align: center;
  border-width: 0;
`;

export const Box = styled.div`
  border-color: #ccc;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
`;

export const EmailInput = styled.input`
  width: 100%;
  border-width: 0;
  padding: 8px;
  border-radius: 8px;
`;

export const CreditCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  border-radius: 8px;
  border-color: #ccc;
  border-width: 1px;
  border-style: solid;
  margin-top: 0px;
  font-family: Proxima Nova;
`;

export const CreditCardNumberContainer = styled.div`
  padding: 16px;
  border-color: #ccc;
  border-width: 0;
  border-bottom-width: 1px;
  border-style: solid;
`;

export const CreditCardExpiryDateContainer = styled.div`
  padding: 16px;
  border-color: #ccc;
  border-width: 0;
  border-right-width: 1px;
  border-style: solid;
  flex: 1;
`;

export const PoweredByContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
