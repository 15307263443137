import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router';
import { IoTrashOutline } from 'react-icons/io5';
import { useAuth0 } from '@auth0/auth0-react';

import {
  Loading,
  Header,
  SigninPage,
  Footer,
  Text,
  CreditCardIcon,
  Root,
  NotFound,
} from './../../components';
import { PRIMARY_COLOR } from 'theme';
import { updateUserData } from './../../actions/user';
import api from 'utils/api';
import './index.css';
import { disableWallets } from 'config';

const CreditCardDetails = ({ eachPaymentMethod }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    }}
  >
    <div
      style={{
        flex: 1,
        textAlign: 'center',
      }}
    >
      <CreditCardIcon type={eachPaymentMethod.card.brand} size="1.5em" />
    </div>
    <Text style={{ flex: 2 }} size={16}>
      xxxx xxxx xxxx {eachPaymentMethod.card.last4}
    </Text>
    <Text style={{ flex: 1, textAlign: 'center' }} size={16}>
      {eachPaymentMethod.card.exp_month}/{eachPaymentMethod.card.exp_year}
    </Text>
  </div>
);

const Index = ({ organization, user, status, auth0User }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const organizationColor =
    organization?.theme?.colors?.primary ?? PRIMARY_COLOR;
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setLoading(false);
      updateUser();
    }
  }, [user]);

  const updateUser = async () => {
    const token = await getAccessTokenSilently();
    if (!user.stripe_default_payment_method) {
      const res = await api.patch(token, `v1/users/${user._id}`, {
        auto_top_up_wallet: false,
      });
    }
  };

  const init = async () => {
    if (isAuthenticated) {
      let token = await getAccessTokenSilently();
      dispatch(updateUserData(token));
    }
  };

  useEffect(() => {
    init();
  }, []);

  if (isAuthenticated && loading) {
    return <Loading />;
  } else if (user?.stripe_payment_methods?.length === 0) {
    return (
      <Root
        organization={organization}
        user={user}
        auth0User={auth0User}
        title="Payment Methods"
        showSupportChat={false}
      >
        <NotFound text="No Payment Method Stored" />
        <div
          style={{
            margin: '2px 16px',
            padding: '2px 16px',
            marginBottom: 16,
          }}
        >
          <Button
            className="action-button"
            style={{
              width: '100%',
              color: 'white',
              backgroundColor: organizationColor,
              borderColor: organizationColor,
            }}
            onClick={() => {
              history.push('/payment_methods/create');
            }}
          >
            Add New Payment Method
          </Button>
        </div>
      </Root>
    );
  } else {
    return (
      <Root
        organization={organization}
        user={user}
        auth0User={auth0User}
        title="Payment Methods"
        showSupportChat={false}
      >
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <>
            <div style={{ marginLeft: 20, marginTop: 20, marginBottom: -16 }}>
              <Text size={12}>Default Payment Method</Text>
            </div>
            {user?.stripe_payment_methods
              ?.filter(
                (eachPaymentMethod) =>
                  eachPaymentMethod.id === user?.stripe_default_payment_method
              )
              .map((eachPaymentMethod) => (
                <div
                  className="credit-card-container"
                  style={{
                    height:
                      user?.stripe_payment_methods.length == 1 ? 150 : 120,
                    justifyContent: 'flex-end',
                  }}
                >
                  <div style={{ display: 'flex', paddingBottom: 16 }}>
                    <Text style={{ flex: 1, textAlign: 'center' }} size={20}>
                      xxxx
                    </Text>
                    <Text style={{ flex: 1, textAlign: 'center' }} size={20}>
                      xxxx
                    </Text>
                    <Text style={{ flex: 1, textAlign: 'center' }} size={20}>
                      xxxx
                    </Text>
                    <Text style={{ flex: 1, textAlign: 'center' }} size={20}>
                      {eachPaymentMethod.card.last4}
                    </Text>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <div style={{ flex: 2, textAlign: 'center' }}>
                      <Text size={16}>
                        {user.firstname} {user.lastname}
                      </Text>
                    </div>

                    <div style={{ flex: 1 }}>
                      <Text style={{ flex: 1, textAlign: 'center' }} size={16}>
                        {eachPaymentMethod.card.exp_month}/
                        {eachPaymentMethod.card.exp_year}
                      </Text>
                    </div>
                    <div
                      style={{
                        flex: 1,
                        textAlign: 'center',
                      }}
                    >
                      <CreditCardIcon
                        type={eachPaymentMethod.card.brand}
                        size="1.5em"
                      />
                    </div>
                  </div>
                  {user?.stripe_payment_methods.length == 1 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 12,
                      }}
                    >
                      <div
                        style={{
                          border: `1px solid ${organizationColor}`,
                          padding: '0.5px 16px',
                          borderRadius: 32,
                          cursor: 'pointer',
                        }}
                        onClick={async () => {
                          setLoading(true);
                          let token = await getAccessTokenSilently();

                          const res = await api.patch(
                            token,
                            `v1/users/${user._id}`,
                            {
                              stripe_payment_methods: [],
                              stripe_default_payment_method: '',
                            }
                          );
                          token = await getAccessTokenSilently();
                          dispatch(updateUserData(token));
                        }}
                      >
                        <IoTrashOutline color={organizationColor} />
                      </div>
                    </div>
                  ) : null}
                </div>
              ))}
            {user?.stripe_payment_methods
              ?.filter(
                (eachPaymentMethod) =>
                  eachPaymentMethod.id !== user?.stripe_default_payment_method
              )
              .map((eachPaymentMethod, index) => (
                <div className="credit-card-container">
                  <CreditCardDetails eachPaymentMethod={eachPaymentMethod} />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: 8,
                      justifyContent: 'flex-end',
                    }}
                    id={`delete-credit-card-button-${index + 1}`}
                  >
                    <div
                      style={{
                        border: `1px solid ${organizationColor}`,
                        padding: '2px 16px',
                        borderRadius: 32,
                        marginRight: 4,
                        cursor: 'pointer',
                      }}
                      onClick={async () => {
                        setLoading(true);
                        let token = await getAccessTokenSilently();

                        const res = await api.patch(
                          token,
                          `v1/users/${user._id}`,
                          {
                            stripe_default_payment_method: eachPaymentMethod.id,
                            stripe_payment_methods: user.stripe_payment_methods,
                          }
                        );
                        token = await getAccessTokenSilently();
                        dispatch(updateUserData(token));
                      }}
                    >
                      <Text size={12} color={organizationColor}>
                        Set as Default
                      </Text>
                    </div>
                    <div
                      style={{
                        border: `1px solid ${organizationColor}`,
                        padding: '0.5px 16px',
                        borderRadius: 32,
                        cursor: 'pointer',
                      }}
                      onClick={async () => {
                        setLoading(true);
                        let _stripe_payment_methods = [];

                        user.stripe_payment_methods
                          .filter(
                            (_eachPaymentMethod) =>
                              _eachPaymentMethod.id !== eachPaymentMethod.id
                          )
                          .map((_eachPaymentMethod) =>
                            _stripe_payment_methods.push(_eachPaymentMethod)
                          );
                        let token = await getAccessTokenSilently();

                        const res = await api.patch(
                          token,
                          `v1/users/${user._id}`,
                          {
                            stripe_payment_methods: _stripe_payment_methods,
                          }
                        );
                        token = await getAccessTokenSilently();
                        dispatch(updateUserData(token));
                      }}
                    >
                      <IoTrashOutline color={organizationColor} />
                    </div>
                  </div>
                </div>
              ))}
          </>
          <div
            style={{
              margin: '2px 16px',
              padding: '2px 16px',
              marginBottom: 16,
            }}
          >
            <Button
              className="action-button"
              style={{
                width: '100%',
                color: 'white',
                backgroundColor: organizationColor,
                borderColor: organizationColor,
              }}
              onClick={() => {
                history.push('/payment_methods/create');
              }}
            >
              Add New Payment Method
            </Button>
          </div>
          {!disableWallets(organization) ? (
            <div
              style={{
                margin: '2px 16px',
                padding: '2px 16px',
                marginBottom: 16,
              }}
            >
              <Button
                className="action-button"
                style={{
                  width: '100%',
                  backgroundColor: 'white',
                  color: 'white',
                  // backgroundColor: organizationColor,
                  borderColor: organizationColor,
                }}
                onClick={() => {
                  history.push('/wallet');
                }}
              >
                <Text color={organizationColor} size={16} weight={600}>
                  Return To Wallet
                </Text>
              </Button>
            </div>
          ) : null}
        </div>
      </Root>
    );
  }
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  user: state.user,
  status: state.status,
  auth0User: state.auth0User,
});

export default connect(mapStateToProps)(Index);
