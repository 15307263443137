import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth0 } from '@auth0/auth0-react';

import {
  Header,
  Footer,
  Loading,
  SigninPage,
  Text,
  Root,
} from './../../../components';

import PaymentForm from './PaymentForm';

const Create = ({ organization, user, auth0User }) => {
  const { isAuthenticated } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(
      organization.configurations?.test_mode
        ? process.env.REACT_APP_DEVELOPMENT_STRIPE_KEY
        : process.env.REACT_APP_PRODUCTION_STRIPE_KEY,
      {
        apiVersion: '2020-08-27',
        stripeAccount: organization.stripe_connected_account_id,
      }
    )
  );

  if (loading) {
    return <Loading />;
  } else {
    return (
      <Root
        organization={organization}
        user={user}
        auth0User={auth0User}
        title="Add Payment Method"
        showSupportChat={false}
      >
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              backgroundColor: 'white',
            }}
          >
            <Elements stripe={stripePromise}>
              <PaymentForm />
            </Elements>
          </div>
        </div>
      </Root>
    );
  }
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  user: state.user,
  status: state.status,
  auth0User: state.auth0User,
});

export default connect(mapStateToProps)(Create);
