import React from 'react';

const KeyboardArrowUp = ({ width, height, pathColor }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <path
      d="M10.59 8L6 3.42L1.41 8L1.23266e-07 6.59L6 0.589996L12 6.59L10.59 8Z"
      fill={pathColor}
    />
  </svg>
);

export default KeyboardArrowUp;
