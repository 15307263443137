import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react';
import { Helmet } from 'react-helmet';
import { Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useCookies } from 'react-cookie';
import { updateUserData } from './actions/user';
import { updateAuth0UserData } from './actions/auth0User';

import 'bootstrap/dist/css/bootstrap.min.css';

import './assets/vendor/nucleo/css/nucleo.css';
import './assets/vendor/font-awesome/css/font-awesome.min.css';
import './assets/css/argon-design-system-react.css';

import './App.css';
import { Loading } from './components';
import {
  HomePage,
  MapsPage,
  ChargestationPage,
  PaymentPage,
  SessionPage,
  ProfilePage,
  ProfileEditPage,
  SigninPage,
  HistoryPage,
  FavoritePage,
  IndexPaymentMethodPage,
  CreatePaymentMethodPage,
  WalletIndexPage,
  PrivateSessionPage,
  PrivateSessionReceiptPage,
  TransactionDetailPage,
  UnauthorizedPage,
} from './../src/pages';
import { getOrganization } from './actions/organization';
import { updateCookie } from './utils/helper';
import api from 'utils/api';

const ConfigureRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth0();
  let userHasMobile = localStorage.getItem('user_has_mobile')
    ? localStorage.getItem('user_has_mobile')
    : true;

  userHasMobile = userHasMobile === 'true' ? true : userHasMobile;
  userHasMobile = userHasMobile === 'false' ? false : userHasMobile;

  if (!isAuthenticated || rest.path === '/profile/edit' || userHasMobile) {
    if (process.env.NODE_ENV === 'production') {
      return <ApmRoute {...rest} component={Component} />;
    } else {
      return <Route {...rest} component={Component} />;
    }
  } else {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: '/profile/edit',
              state: { from: 'default' },
            }}
          />
        )}
      />
    );
  }
};

function App({ organization, _user, auth0Uer }) {
  const { isLoading, isAuthenticated, user, getAccessTokenSilently } =
    useAuth0();

  const auth0User = user;
  const dispatch = useDispatch();
  const [cookies, setCookies] = useCookies([]);

  const init = async () => {
    let currentDate = new Date();

    if (!(cookies.store && cookies.store.createdAt)) {
      updateCookie(
        {
          transactionId: null,
          createdAt: currentDate.toUTCString(),
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        cookies,
        setCookies
      );
    }

    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      const response_transaction = await api.get(
        token,
        `v1/transactions?status=Started`
      );
      const transaction = response_transaction?.result ? response_transaction?.result[0] : null;

      if (transaction && transaction.session && transaction.user == _user._id) {
        updateCookie(
          {
            sessionId: transaction?.session ?? null,
          },
          cookies,
          setCookies
        );
      }
    }
  };

  const fetchUser = async () => {
    const token = await getAccessTokenSilently();
    dispatch(updateUserData(token));
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
    script.async = true;

    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchUser();
    }
    dispatch(updateAuth0UserData(user));
  }, [user]);

  useEffect(() => {
    dispatch(getOrganization());
  }, [dispatch]);

  if (organization.loading || isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <Helmet>
          <title>
            {organization?.driver_application?.html_title ?? 'EV Charging'}
          </title>
          <link
            rel="icon"
            href={`${organization?.logo ?? '%PUBLIC_URL%/edrv-icon.jpg'}`}
          />
        </Helmet>
        <Router>
          <Switch>
            {/* <ConfigureRoute
            exact
            path="/signin"
            component={SigninPage}
            user={user}
          /> */}
            <ConfigureRoute
              exact
              path="/profile"
              render={(props) => {
                if (isAuthenticated) {
                  return <ProfilePage {...props} />;
                } else {
                  return (
                    <UnauthorizedPage {...props} title="Profile Details" />
                  );
                }
              }}
              _user={_user}
            />
            <ConfigureRoute
              exact
              path="/transaction_history"
              render={(props) => {
                if (isAuthenticated) {
                  return <HistoryPage {...props} />;
                } else {
                  return <UnauthorizedPage {...props} title="Transactions" />;
                }
              }}
              _user={_user}
            />
            <ConfigureRoute
              exact
              path="/payment_methods"
              render={(props) => {
                if (isAuthenticated) {
                  return <IndexPaymentMethodPage {...props} />;
                } else {
                  return (
                    <UnauthorizedPage {...props} title="Payment Methods" />
                  );
                }
              }}
              _user={_user}
            />
            <ConfigureRoute
              exact
              path="/payment_methods/create"
              render={(props) => {
                if (isAuthenticated) {
                  return <CreatePaymentMethodPage {...props} />;
                } else {
                  return (
                    <UnauthorizedPage {...props} title="Add Payment Method" />
                  );
                }
              }}
              _user={_user}
            />
            <ConfigureRoute
              exact
              path="/wallet"
              render={(props) => {
                if (isAuthenticated) {
                  return <WalletIndexPage {...props} />;
                } else {
                  return <UnauthorizedPage {...props} title="Balance" />;
                }
              }}
              _user={_user}
            />
            <ConfigureRoute
              exact
              path="/favorites"
              render={(props) => {
                if (isAuthenticated) {
                  return <FavoritePage {...props} />;
                } else {
                  return <UnauthorizedPage {...props} title="Favorites" />;
                }
              }}
              _user={_user}
            />
            <ConfigureRoute
              exact
              path="/profile/edit"
              render={(props) => {
                if (isAuthenticated) {
                  return <ProfileEditPage {...props} />;
                } else {
                  return (
                    <UnauthorizedPage {...props} title="Edit Profile Details" />
                  );
                }
              }}
              _user={_user}
            />
            <ConfigureRoute path="/maps" component={MapsPage} _user={_user} />
            {/* <ConfigureRoute
            path="/payment/:connectorId"
            component={PaymentPage}
            _user={_user}
          /> */}
            <ConfigureRoute
              path="/payment/:payment_id/"
              component={PaymentPage}
              _user={_user}
            />
            <ConfigureRoute
              path="/session/:paymentIntentId"
              component={SessionPage}
              _user={_user}
            />
            {/* <ConfigureRoute
            path="/private/session/:session_id/receipt"
            component={PrivateSessionReceiptPage}
            _user={_user}
          /> */}
            <ConfigureRoute
              path="/private/session/:session_id"
              component={PrivateSessionPage}
              _user={_user}
            />
            <ConfigureRoute
              path="/transaction/:id"
              render={(props) => {
                if (isAuthenticated) {
                  return <TransactionDetailPage {...props} />;
                } else {
                  return (
                    <UnauthorizedPage {...props} title="Transaction Details" />
                  );
                }
              }}
              _user={_user}
            />
            <ConfigureRoute
              path="/:endpoint"
              component={ChargestationPage}
              _user={_user}
            />
            <ConfigureRoute path="/" component={HomePage} _user={_user} />
          </Switch>
        </Router>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  organization: state.organization,
  _user: state.user,
  auth0Uer: state.auth0User,
});

export default connect(mapStateToProps)(App);
