import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { PRIMARY_COLOR } from 'theme';

import Text from './../Text/Text';

const Logout = ({ organization, style }) => {
  const { isAuthenticated, logout, isLoading } = useAuth0();

  const organizationColor =
    organization?.theme?.colors?.primary ?? PRIMARY_COLOR;

  if (!isLoading && isAuthenticated) {
    return (
      <div
        style={{
          padding: '16px 8px',
          textAlign: 'center',
          backgroundColor: 'white',
          ...style,
        }}
        onClick={() => {
          localStorage.setItem('redirectAfterAuth0', window.location.pathname);
          logout({
            returnTo: `${window.location.protocol}//${window.location.host}`,
          });
        }}
      >
        <Text size={16} weight={600} color={organizationColor}>
          Log Out
        </Text>
      </div>
    );
  }

  return null;
};

export default Logout;
