import React from 'react';

const ConnectorSinglePin = ({
  width,
  height,
  backgroundColor,
  ringColor,
  onClick,
}) => (
  <svg
    viewBox="0 0 27 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    onClick={onClick}
  >
    <path
      d="M0.5 13.0867C0.5 6.14491 6.23796 0.5 13.3333 0.5C20.4287 0.5 26.1667 6.14491 26.1667 13.0867C26.1667 19.5078 21.5361 24.1204 14.9762 29.8964C14.0379 30.7225 12.6287 30.7225 11.6905 29.8964C5.1306 24.1204 0.5 19.5078 0.5 13.0867Z"
      stroke="#1FBD74"
      fill={backgroundColor}
    />
    <path
      d="M13.3333 22.7037C8.15729 22.7037 3.96289 18.5082 3.96289 13.3333C3.96289 8.1585 8.15729 3.96296 13.3333 3.96296C18.5092 3.96296 22.7036 8.1585 22.7036 13.3333C22.7036 18.5082 18.5092 22.7037 13.3333 22.7037Z"
      stroke-width="2"
      stroke={ringColor}
    />
    <path
      d="M16.4587 13.2375C16.4272 13.1222 16.3326 13.0299 16.2276 12.9953L14.1168 12.488L14.8939 8.55605C14.9254 8.40615 14.8519 8.24472 14.7259 8.17554C14.5894 8.11788 14.4424 8.15247 14.3374 8.26778L10.4518 13.1106C10.3783 13.1914 10.3573 13.3182 10.3783 13.422C10.3993 13.5258 10.4728 13.618 10.5779 13.6641L12.6151 14.4713L11.649 18.0804C11.607 18.2303 11.67 18.4032 11.796 18.4724C11.8485 18.507 11.901 18.5185 11.9535 18.5185C12.0376 18.5185 12.1216 18.4839 12.1846 18.4147L16.3851 13.5719C16.4587 13.4912 16.4902 13.3643 16.4587 13.2375Z"
      fill="white"
    />
  </svg>
);

export default ConnectorSinglePin;
