import React from 'react';
import { FiCreditCard } from 'react-icons/fi';
import {
  FaCcMastercard,
  FaCcVisa,
  FaCcAmex,
  FaCcDiscover,
  FaCcDinersClub,
  FaCcJcb,
} from 'react-icons/fa';

const CreditCardIcon = (props) => {
  let { type } = props;
  type = type.toLowerCase();

  if (type === 'visa') {
    return <FaCcVisa {...props} />;
  } else if (type === 'mastercard') {
    return <FaCcMastercard {...props} />;
  } else if (type === 'amex') {
    return <FaCcAmex {...props} />;
  } else if (type === 'discover') {
    return <FaCcDiscover {...props} />;
  } else if (type === 'diners') {
    return <FaCcDinersClub {...props} />;
  } else if (type === 'jcb') {
    return <FaCcJcb {...props} />;
  } else {
    return <FiCreditCard {...props} />;
  }
};

export default CreditCardIcon;
