import React from 'react';

const J1772Icon = ({ width, height, pathColor }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <path
      d="M24.8883 20.4825C24.8883 20.0487 24.541 19.6727 24.078 19.6727C23.6439 19.6727 23.2676 20.0198 23.2676 20.4825C23.2676 20.9164 23.6149 21.2924 24.078 21.2924C24.541 21.2635 24.8883 20.9164 24.8883 20.4825Z"
      fill={pathColor}
    />
    <path
      d="M10.7364 20.4825C10.7364 20.0487 10.3891 19.6727 9.92602 19.6727C9.46298 19.6727 9.11569 20.0198 9.11569 20.4825C9.11569 20.9164 9.46298 21.2924 9.92602 21.2924C10.3891 21.2924 10.7364 20.9164 10.7364 20.4825Z"
      fill={pathColor}
    />
    <path
      d="M11.9519 11.0244C12.6175 11.0244 13.1674 11.574 13.1674 12.2392C13.1674 12.9045 12.6175 13.454 11.9519 13.454C11.2862 13.454 10.7364 12.9045 10.7364 12.2392C10.7364 11.574 11.2862 11.0244 11.9519 11.0244Z"
      fill={pathColor}
    />
    <path
      d="M23.2387 12.2392C23.2387 11.574 22.6888 11.0244 22.0232 11.0244C21.3575 11.0244 20.8077 11.574 20.8077 12.2392C20.8077 12.9045 21.3575 13.454 22.0232 13.454C22.7178 13.454 23.2387 12.9045 23.2387 12.2392Z"
      fill={pathColor}
    />
    <path
      d="M16.9875 22.4494C17.6532 22.4494 18.203 22.9989 18.203 23.6642C18.203 24.3294 17.6532 24.879 16.9875 24.879C16.3219 24.879 15.772 24.3294 15.772 23.6642C15.772 22.9989 16.3219 22.4494 16.9875 22.4494Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29 17C29 23.6274 23.6274 29 17 29C10.3726 29 5 23.6274 5 17C5 10.3726 10.3726 5 17 5C23.6274 5 29 10.3726 29 17ZM26.6537 20.4825C26.6537 19.0653 25.525 17.9372 24.1069 17.9372C22.6888 17.9372 21.5601 19.0653 21.5601 20.4825C21.5601 21.8998 22.6888 23.0278 24.1069 23.0278C25.525 23.0278 26.6537 21.8709 26.6537 20.4825ZM12.4728 20.4825C12.4728 19.0653 11.3441 17.9372 9.92602 17.9372C8.50794 17.9372 7.37926 19.0653 7.37926 20.4825C7.37926 21.8998 8.50794 23.0278 9.92602 23.0278C11.3441 23.0278 12.4728 21.8709 12.4728 20.4825ZM15.6563 12.2392C15.6563 10.1856 14.0066 8.53694 11.9519 8.53694C9.89708 8.53694 8.24747 10.1856 8.24747 12.2392C8.24747 14.2928 9.89708 15.9415 11.9519 15.9415C14.0066 15.9415 15.6563 14.2639 15.6563 12.2392ZM25.7276 12.2392C25.7276 10.1856 24.078 8.53694 22.0232 8.53694C19.9684 8.53694 18.3188 10.1856 18.3188 12.2392C18.3188 14.2928 19.9684 15.9415 22.0232 15.9415C24.078 15.9415 25.7276 14.2639 25.7276 12.2392ZM20.6919 23.6642C20.6919 21.6106 19.0423 19.9619 16.9875 19.9619C14.9327 19.9619 13.2831 21.6106 13.2831 23.6642C13.2831 25.7178 14.9327 27.3664 16.9875 27.3664C19.0423 27.3664 20.6919 25.6889 20.6919 23.6642Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M34 17C34 26.3889 26.3879 34 17 34C7.61205 34 0 26.3889 0 17C0 7.6111 7.61205 0 17 0C26.3879 0 34 7.6111 34 17ZM32.0571 17C32.0571 25.3158 25.316 32.0571 17 32.0571C8.68404 32.0571 1.94286 25.3158 1.94286 17C1.94286 8.68416 8.68404 1.94286 17 1.94286C25.316 1.94286 32.0571 8.68416 32.0571 17Z"
      fill={pathColor}
    />
  </svg>
);

export default J1772Icon;
