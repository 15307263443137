import { UPDATE_LOCATIONS_DATA_ACTION as UPDATE_LOCATIONS_DATA } from './../constants';

const initialState = {};

const locations = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LOCATIONS_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default locations;
