import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Header,
  Text,
  Footer,
  SigninPage,
  Notification,
  Loading,
  NotFound,
  Root,
} from './../../../components';
import { PRIMARY_COLOR } from './../../../theme';
import { ToastZoomTransition } from './../../../utils/helper';
import api from './../../../utils/api';
import { updateUserData } from './../../../actions/user';

import './ProfileEdit.css';
import { useAuth0 } from '@auth0/auth0-react';

const ProfileEdit = ({ organization, user, auth0User }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    profilePicture: '',
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    newPassword: '',
    repeatNewPassword: '',
  });

  const organizationColor = organization?.theme?.colors?.primary
    ? organization?.theme?.colors?.primary
    : PRIMARY_COLOR;

  const [canUpdatePassword, setCanUpdatePassword] = useState(false);
  const [showNotification, setShowNotification] = useState(
    location?.state?.from === 'default'
  );

  useEffect(() => {
    if (user) {
      setFormData({
        ...formData,
        firstName: user?.firstname,
        lastName: user?.lastname,
        email: user?.email,
        mobileNumber: user?.phone?.mobile,
        profilePicture: auth0User?.picture ?? formData.picture,
      });
      setLoading(false);
    }
  }, [user, auth0User]);

  useEffect(() => {
    // We can tell how an account is connected by checking the prefix of the sub property
    if (auth0User && auth0User.sub && auth0User.sub.includes('auth0')) {
      setCanUpdatePassword(true);
    }
  }, [auth0User]);

  const handleChange = async (e) => {
    const { target } = e;
    const { name, value } = target;

    setFormData({ ...formData, [name]: value });
  };

  const onFormSubmit = async () => {
    const {
      firstName,
      lastName,
      mobileNumber,
      newPassword,
      repeatNewPassword,
    } = formData;

    if (!firstName) {
      return toast.error('First name is required');
    }

    if (!lastName) {
      return toast.error('Last name is required');
    }

    if (!mobileNumber) {
      return toast.error('Mobile number is required');
    }

    if (mobileNumber.length < 10) {
      return toast.error('Please enter a valid phone number');
    }

    if (newPassword || repeatNewPassword) {
      if (newPassword.length < 8) {
        return toast.error(
          'Password can not be empty or less than 8 characters'
        );
      }
      if (!newPassword) {
        return toast.error('New password is required');
      }
      if (!repeatNewPassword) {
        return toast.error('Repeat new password is required');
      }
      if (newPassword !== repeatNewPassword) {
        return toast.error('New password and repeat password must match');
      }
    }

    let token = await getAccessTokenSilently();

    if (token) {
      const res = await api.patch(token, `v1/users/${user._id}`, {
        firstname: firstName,
        lastname: lastName,
        phone: { ...user.phone, mobile: mobileNumber },
      });

      if (newPassword) {
        token = await getAccessTokenSilently();
        const passwordRes = await api.patch(token, `v1/users/current`, {
          password: newPassword,
        });
      }
      token = await getAccessTokenSilently();

      dispatch(updateUserData(token));
      toast.error('Profile updated');
      // localStorage.setItem('user_has_mobile', true);
      setTimeout(() => {
        history.push('/profile');
      }, 1500);
    }
  };

  const renderPasswordUpdate = () => {
    if (canUpdatePassword) {
      return (
        <>
          <div style={{ margin: '12px 24px' }}>
            <Text size={16} weight={600}>
              Password
            </Text>
          </div>
          <div className="profile-edit-detail-container">
            <input
              name="newPassword"
              type="password"
              className="profile-edit-detail-input"
              placeholder="New Password"
              value={formData.newPassword}
              onChange={handleChange}
            />
          </div>
          <div className="profile-edit-detail-container">
            <input
              name="repeatNewPassword"
              type="password"
              className="profile-edit-detail-input"
              placeholder="Repeat new password"
              value={formData.repeatNewPassword}
              onChange={handleChange}
            />
          </div>
        </>
      );
    }
    return null;
  };

  if (loading) {
    return <Loading></Loading>;
  } else if (!loading && !user) {
    return (
      <Root
        organization={organization}
        user={user}
        auth0User={auth0User}
        title="Edit Profile Details"
        showSupportChat={false}
      >
        <NotFound text="User Not Found" />
      </Root>
    );
  } else {
    return (
      <>
        <Notification
          message="Please complete your profile"
          isShowing={showNotification}
          timeoutSeconds={3}
          hide={() => {
            setShowNotification(false);
          }}
          organizationColor={organizationColor}
        />
        <ToastContainer
          position="bottom-center"
          hideProgressBar
          autoClose={2500}
          transition={ToastZoomTransition()}
          closeButton={false}
        />
        <Root
          organization={organization}
          user={user}
          auth0User={auth0User}
          title="Edit Profile Details"
          showSupportChat={false}
        >
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <div className="profile-picture-container">
              <img className="profile-picture" src={formData.profilePicture} />
            </div>
            <div style={{ margin: '12px 24px' }}>
              <Text size={16} weight={600}>
                Details
              </Text>
            </div>
            <div className="profile-edit-detail-container">
              <input
                name="firstName"
                className="profile-edit-detail-input"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>
            <div className="profile-edit-detail-container">
              <input
                name="lastName"
                className="profile-edit-detail-input"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
            <div className="profile-edit-detail-container">
              <input
                name="email"
                className="profile-edit-detail-input"
                placeholder="Email"
                value={formData.email}
                disabled
              />
            </div>
            <div className="profile-edit-detail-container">
              <input
                name="mobileNumber"
                className="profile-edit-detail-input"
                placeholder="Mobile Number"
                value={formData.mobileNumber}
                onChange={handleChange}
              />
            </div>
            {renderPasswordUpdate()}
            <br />
            <div className="profile-edit-button-container">
              <Button
                className="action-button"
                style={{
                  backgroundColor: organizationColor,
                  borderColor: organizationColor,
                }}
                onClick={() => onFormSubmit()}
              >
                Update
              </Button>
            </div>
          </div>
        </Root>
      </>
    );
  }
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  status: state.status,
  user: state.user,
  auth0User: state.auth0User,
});

export default connect(mapStateToProps)(ProfileEdit);
