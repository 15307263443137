import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { IoLocationOutline, IoWallet } from 'react-icons/io5';
import moment from 'moment';
import { useAuth0 } from '@auth0/auth0-react';

import {
  Header,
  Footer,
  Text,
  SigninPage,
  Loading,
  ConnectorIcon,
  TransactionsStats,
  Root,
  NotFound,
} from './../../components';
import { PRIMARY_COLOR, DEFAULT_GREY } from './../../theme';
import { hhmmss } from 'utils/helper';
import { updateHistoryData } from 'actions/history';

import './history.css';
import api from 'utils/api';
import { useHistory } from 'react-router-dom';

const TransactionCard = ({
  transaction,
  organizationColor,
  locationsData,
  connectorData,
  onClick,
}) => {
  return (
    <div onClick={onClick} style={{ cursor: 'pointer' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IoLocationOutline
          size="1.5em"
          color={organizationColor}
          style={{ marginRight: 8 }}
        />
        <Text size={12} weight={400} color={organizationColor}>
          {locationsData[transaction?.chargestation?.location]?.address
            ?.streetAndNumber
            ? `${
                locationsData[transaction?.chargestation?.location]?.address
                  ?.streetAndNumber
              }, `
            : null}

          {locationsData[transaction?.chargestation?.location]?.address?.city
            ? `${
                locationsData[transaction?.chargestation?.location]?.address
                  ?.city
              }, `
            : null}
          {locationsData[transaction?.chargestation?.location]?.address?.state
            ? `${
                locationsData[transaction?.chargestation?.location]?.address
                  ?.state
              }, `
            : null}
          {locationsData[transaction?.chargestation?.location]?.address
            ?.postalCode
            ? `${
                locationsData[transaction?.chargestation?.location]?.address
                  ?.postalCode
              }, `
            : null}
          {
            locationsData[transaction?.chargestation?.location]?.address
              ?.country
          }
        </Text>
      </div>
      <div style={{ padding: '0 4px' }}>
        <Text size={12} weight={400} color={DEFAULT_GREY}>
          Station ID: {transaction?.chargestation?.static_endpoint ?? '-'}
        </Text>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '0 4px',
        }}
      >
        <Text size={12} weight={400}>
          {hhmmss(Math.floor(transaction?.metrics?.timeSpentCharging))}
        </Text>
        <Text size={12} weight={400}>
          {moment(transaction?.metrics?.chargingStart)
            .utc()
            .local()
            .format('hh:mm A')}{' '}
          {' - '}
          {moment(
            new Date(
              new Date(transaction?.metrics?.chargingStart).getTime() +
                transaction?.metrics?.timeSpentCharging * 1000
            )
          ).format('hh:mm A, D MMM')}
        </Text>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: '0 4px',
          paddingTop: 4,
          alignItems: 'center',
        }}
      >
        <div>
          <ConnectorIcon
            type={connectorData[transaction.connector]?.type}
            color={organizationColor}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            padding: '0 8px',
          }}
        >
          <Text>Volume:</Text>
          <Text>
            {transaction?.metrics && transaction?.metrics?.wattHoursConsumed
              ? (transaction?.metrics?.wattHoursConsumed / 1000).toLocaleString(
                  undefined,
                  {
                    maximumFractionDigits: 3,
                    minimumFractionDigits: 3,
                  }
                )
              : '0'}{' '}
            kWh
          </Text>
        </div>
        <div>
          <Text>
            {transaction?.cost?.currency
              ? `${parseFloat(transaction?.cost?.amount ?? 0).toFixed(2)}
                          ${transaction?.cost?.currency?.toUpperCase()}`
              : '-'}
          </Text>
        </div>
      </div>
    </div>
  );
};

const BalanceCard = ({ transaction, organizationColor }) => {
  if (transaction.amount > 0) return null;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '8px 0',
      }}
    >
      <div>
        <IoWallet
          size="2.5em"
          color={organizationColor}
          style={{ marginRight: 8, marginLeft: 4 }}
        />
      </div>
      <Text size={14} color={organizationColor} style={{ flex: 2 }}>
        {transaction.description}
      </Text>
      <Text size={12} style={{ flex: 1, textAlign: 'end' }}>
        {moment(new Date(transaction.createdAt)).format('hh:mm A, D MMM')}
      </Text>
    </div>
  );
};

const History = ({ organization, user, history, auth0User }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const _history = useHistory();
  const [loading, setLoading] = useState(true);
  const [historyData, setHistoryData] = useState(history ?? []);
  const [locationsData, setLocationsData] = useState({});
  const [connectorData, setConnectorData] = useState({});
  const [customBalanceTransactions, setCustomBalanceTransactions] = useState(
    []
  );
  const organizationColor = organization?.theme?.colors?.primary
    ? organization?.theme?.colors?.primary
    : PRIMARY_COLOR;

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (customBalanceTransactions && history) {
      let data = [...customBalanceTransactions, ...history];
      data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      setHistoryData(data);
      fetchChargestationsData();
      setLoading(false);
    }
  }, [history, customBalanceTransactions]);

  const fetchChargestationsData = async () => {
    const response = await window.fetch(
      `${process.env.REACT_APP_API_URL}/v1/public/chargestations?include_location=true&organization=${organization.id}&paginate_limit=1000`
    );

    if (response.status === 200) {
      const jsonResponse = await response.json();
      const _locations = {};

      for (const index in jsonResponse.result) {
        _locations[jsonResponse.result[index]?.location._id] =
          jsonResponse.result[index]?.location;
      }

      const _connectors = {};

      for (const index in jsonResponse.result) {
        for (const connectorIndex in jsonResponse.result[index].connectors) {
          _connectors[
            jsonResponse.result[index].connectors[connectorIndex]._id
          ] = jsonResponse.result[index].connectors[connectorIndex];
        }
      }

      setConnectorData(_connectors);
      setLocationsData(_locations);
    }
  };

  const fetchData = async () => {
    const token = await getAccessTokenSilently();
    dispatch(updateHistoryData(token, { user: user._id }));
    const response_wallet = await api.get(token, 'v1/wallet');
    response_wallet.result.custom_balance_transactions.forEach(
      (eachTransaction) => {
        eachTransaction.createdAt = eachTransaction.createdAt * 1000;
      }
    );
    let data = response_wallet.result.custom_balance_transactions.filter(
      (eachTransaction) => eachTransaction.amount < 0
    );
    setCustomBalanceTransactions(data);
  };

  if (loading) {
    return <Loading></Loading>;
  } else if (!historyData || historyData.length === 0) {
    return (
      <Root
        organization={organization}
        user={user}
        auth0User={auth0User}
        title="Transactions"
        showSupportChat={false}
      >
        <NotFound text="No Records Found" />
      </Root>
    );
  } else {
    return (
      <Root
        organization={organization}
        user={user}
        auth0User={auth0User}
        title="Transactions"
        showSupportChat={false}
      >
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <TransactionsStats
            data={historyData.filter(
              (eachHistoryData) => eachHistoryData.status
            )}
          />
          {historyData.map((eachHistoryData) => (
            <>
              <div
                key={eachHistoryData._id}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: 'white',
                  padding: '12px 16px',
                  borderBottom: '1px solid #DDD',
                }}
              >
                {eachHistoryData.status ? (
                  <TransactionCard
                    organizationColor={organizationColor}
                    transaction={eachHistoryData}
                    locationsData={locationsData}
                    connectorData={connectorData}
                    onClick={() => {
                      if (eachHistoryData.status === 'Started') {
                        _history.push(
                          `/private/session/${eachHistoryData.session}`
                        );
                      } else {
                        _history.push(`/transaction/${eachHistoryData._id}`);
                      }
                    }}
                  />
                ) : eachHistoryData.amount < 0 ? (
                  <BalanceCard
                    transaction={eachHistoryData}
                    organizationColor={organizationColor}
                  />
                ) : null}
              </div>
            </>
          ))}
        </div>
      </Root>
    );
  }
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  user: state.user,
  status: state.status,
  history: state.history,
  locations: state.locations,
  auth0Uer: state.auth0User,
});
export default connect(mapStateToProps)(History);
