import React from 'react';

import { CONNECTOR_STATUS } from './../../constants';
import Text from './../Text/Text';
import ConnectorIcon from './../ConnectorIcon/ConnectorIcon';
import './Connector.css';
import { getRates, isFreeRate } from 'utils/helper';

const RateDescription = ({ rate, minAmount }) => {
  const freeRate = isFreeRate(rate);

  if (freeRate) {
    return (
      <Text weight={400} size={12}>
        Free
      </Text>
    );
  }

  return (
    <>
      <Text weight={400} size={12}>
        {getRates(rate)[0]}
      </Text>
      {rate?.type !== 'schedule' ? (
        minAmount ? (
          <Text weight={400} size={12}>
            {minAmount}
          </Text>
        ) : (
          <Text weight={400} size={12}>
            {rate?.alt_text[0].text.split('+')[1]
              ? `${rate?.alt_text[0].text.split('+')[1]}`
              : ''}
          </Text>
        )
      ) : (
        <Text weight={400} size={12}>
          See detailed rate below
        </Text>
      )}
    </>
  );
};

const Connector = ({
  connector,
  isSelected,
  organizationColor,
  onClick,
  chargestation = {},
  minAmount,
  id,
  rate,
}) => {
  return (
    <div
      className={'connector-container ' + (isSelected ? 'selected' : '')}
      style={{
        backgroundColor: isSelected ? `${organizationColor}10` : 'white',
        borderColor: isSelected ? organizationColor : null,
      }}
      onClick={onClick}
      id={id}
    >
      <div
        className={
          connector.status === CONNECTOR_STATUS.AVAILABLE &&
          chargestation.online
            ? 'connector-status-live'
            : 'connector-status-dead'
        }
      ></div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ConnectorIcon type={connector?.type} color={organizationColor} />
      </div>
      <div className="connector-detail">
        <div className="connector-detail-1">
          <Text
            weight={600}
            size={16}
            color={organizationColor}
            style={{ marginTop: 4 }}
          >
            {connector?.power} kW
          </Text>
          <Text weight={400} size={12}>
            {connector?.type}
          </Text>
        </div>
        <div className="connector-detail-2">
          <Text weight={400} size={10} color={organizationColor}>
            {connector?.power_type?.includes('AC') ? 'AC' : 'DC'}
          </Text>
          <RateDescription rate={rate} minAmount={minAmount} />
        </div>
      </div>
    </div>
  );
};

export default Connector;
