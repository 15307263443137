import React from 'react';

const KeyboardArrowDown = ({ width, height, pathColor }) => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <path
      d="M1.41 0.59L6 5.17L10.59 0.59L12 2L6 8L0 2L1.41 0.59Z"
      fill={pathColor}
    />
  </svg>
);

export default KeyboardArrowDown;
