import React from 'react';

const Flash = ({ width, height, pathColor }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <path
      d="M12.9741 9.81515C12.9069 9.59278 12.7055 9.41487 12.4817 9.34816L7.98349 8.3697L9.63956 0.786662C9.7067 0.497572 9.55004 0.186245 9.28149 0.0528189C8.99056 -0.0583694 8.67725 0.00834353 8.45345 0.23072L0.173106 9.57054C0.0164504 9.7262 -0.0283084 9.97082 0.0164502 10.171C0.0612089 10.3711 0.217864 10.549 0.441657 10.6379L4.78325 12.1946L2.72435 19.155C2.63483 19.4441 2.76911 19.7776 3.03766 19.911C3.14955 19.9778 3.26145 20 3.37335 20C3.55238 20 3.73142 19.9333 3.86569 19.7999L12.8174 10.46C12.9741 10.3044 13.0412 10.0598 12.9741 9.81515Z"
      fill={pathColor}
    />
  </svg>
);

export default Flash;
