import { combineReducers } from 'redux';

import user from './user';
import organization from './organization';
import status from './status';
import auth0User from './auth0User';
import history from './history';
import locations from './locations';

export default combineReducers({
  user,
  organization,
  status,
  auth0User,
  history,
  locations,
});
