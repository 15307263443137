import api from 'utils/api';
import { UPDATE_USER_DATA_ACTION } from './../constants';

export const updateUserData = (token) => {
  const request = api.get(token, 'v1/users/current');

  return (dispatch) =>
    request.then((response) =>
      dispatch({
        type: UPDATE_USER_DATA_ACTION,
        payload: response.result,
      })
    );
};
