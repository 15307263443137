import React from 'react';

import { Text } from './../../components';
import './Information.css';

//TODO remove inline style
const Information = ({ Icon, type, value, href, color, show = true }) => {
  if (!show) return null;

  return (
    <div className="parking-container">
      <div className="information-icon">
        <Icon size={20} />
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Text
          size={14}
          weight={400}
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {type}
        </Text>
        {href ? (
          <a href={href}>
            <Text
              size={14}
              weight={600}
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                textAlign: 'end',
              }}
              color={color}
            >
              {value}
            </Text>
          </a>
        ) : (
          <Text
            size={14}
            weight={600}
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              textAlign: 'end',
            }}
          >
            {value}
          </Text>
        )}
      </div>
    </div>
  );
};

export default Information;
