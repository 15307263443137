import React from 'react';
import { Spinner } from 'reactstrap';

const Loading = () => {
  return (
    <div
      style={{
        display: 'block',
        position: 'fixed',
        top: '50%',
        right: '50%',
        marginTop: -30,
        marginRight: -15,
      }}
    >
      <Spinner color="primary" />
    </div>
  );
};

export default Loading;
