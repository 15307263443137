import React from 'react';

const ConnectorDoublePin = ({
  width,
  height,
  backgroundColor,
  leftRingColor,
  rightRingColor,
  onClick,
}) => (
  <svg
    viewBox="0 0 36 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    onClick={onClick}
  >
    <path
      d="M0.5 17.667C0.5 8.19591 8.32769 0.5 18 0.5C27.6723 0.5 35.5 8.19591 35.5 17.667C35.5 22.156 33.8385 25.9964 31.0217 29.7274C28.1953 33.471 24.2276 37.0769 19.6447 41.0968C18.7043 41.9216 17.2957 41.9216 16.3553 41.0968C11.7724 37.0769 7.80468 33.471 4.97831 29.7274C2.1615 25.9964 0.5 22.156 0.5 17.667Z"
      stroke="#1FBD74"
      fill={backgroundColor}
    />
    <path
      d="M16 5C9.77996 5.91031 5 11.3842 5 18C5 24.6158 9.77996 30.0897 16 31"
      stroke-width="2"
      stroke-linecap="round"
      stroke={leftRingColor}
    />
    <path
      d="M20 31C26.22 30.0897 31 24.6158 31 18C31 11.3842 26.22 5.91031 20 5"
      stroke-width="2"
      stroke-linecap="round"
      stroke={rightRingColor}
    />
    <path
      d="M22.2189 17.8706C22.1763 17.7149 22.0487 17.5904 21.907 17.5437L19.0574 16.8588L20.1065 11.5507C20.149 11.3483 20.0498 11.1304 19.8797 11.037C19.6954 10.9591 19.4969 11.0058 19.3551 11.1615L14.1097 17.6994C14.0104 17.8083 13.9821 17.9796 14.0104 18.1197C14.0388 18.2598 14.138 18.3843 14.2798 18.4466L17.0301 19.5362L15.7258 24.4085C15.6691 24.6108 15.7542 24.8443 15.9243 24.9377C15.9952 24.9844 16.0661 25 16.137 25C16.2504 25 16.3638 24.9533 16.4489 24.8599L22.1196 18.322C22.2189 18.2131 22.2614 18.0418 22.2189 17.8706Z"
      fill="white"
    />
  </svg>
);

export default ConnectorDoublePin;
