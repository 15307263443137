const baseUrl = process.env.REACT_APP_API_URL;

export default {
  get: (token, endpoint, qs) => {
    return fetch(`${baseUrl}/${endpoint}${qs ? qs : ''}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json());
  },
  post: (token, endpoint, payload) => {
    return fetch(`${baseUrl}/${endpoint}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }).then((res) => res.json());
  },
  patch: (token, endpoint, payload) => {
    return fetch(`${baseUrl}/${endpoint}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }).then((res) => res.json());
  },
  delete: (token, endpoint) => {
    return fetch(`${baseUrl}/${endpoint}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json());
  },
};

export { baseUrl };
