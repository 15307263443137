import { UPDATE_AUTH0_USER_DATA_ACTION as UPDATE_AUTH0_USER_DATA } from './../constants';

const initialState = {};

const auth0User = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_AUTH0_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default auth0User;
