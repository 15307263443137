import React from 'react';

import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';

const Root = ({
  organization,
  user,
  auth0User,
  title,
  children,
  showSupportChat,
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="unauthorized-body">
        <Header
          organization={organization}
          user={user}
          auth0User={auth0User}
          title={title}
        />
        {children}
      </div>
      <Footer organization={organization} showSupportChat={showSupportChat} />
    </div>
  );
};

export default Root;
