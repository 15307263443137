import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import _ from 'lodash';
import qs from 'qs';
import punycode from 'punycode';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { useHistory, useLocation } from 'react-router-dom';
import { IoMdLocate } from 'react-icons/io';
import { IoLocationSharp, IoLinkOutline } from 'react-icons/io5';

import {
  SearchIcon,
  CloseOutlineIcon,
  Loading,
  Text,
  NavigationIcon,
  ConnectorCard,
  BackArrowIcon,
  FlashIcon,
  LocationIcon,
  DetectSession,
  DetectLowBalance,
  GoogleMapWithChargestations,
} from './../../components';
import CopyIcon from './../../assets/img/copy.svg';
import './Maps.css';
import {
  updateLocationInCookie,
  rad,
  ToastZoomTransition,
  updateCookie,
  convertCurrencyFromText,
  getOrganizationColor,
  getMinimumAmount,
} from './../../utils/helper';
import { DEFAULT_GREY } from 'theme';
import { DEFAULT_LOCATION } from './../../constants';
import api from 'utils/api';
import ChargestationService from 'services/chargestation';

//TODO refactor search box to its own component
const SearchBox = ({ address, handleSelect, onClear, chargeStations }) => {
  const history = useHistory();
  const [searchText, setSearchText] = useState(address);

  const handleChange = (_searchText) => {
    setSearchText(_searchText);
  };

  return (
    <div
      style={{
        borderWidth: 0,
        borderRadius: 6,
        maxWidth: 500,
        margin: '8px 0px',
        position: 'absolute',
        top: 20,
        width: '96%',
        margin: '2%',
        backgroundColor: 'white',
        boxShadow: '0 2px 4px rgba(0,0,0,0.2), 0 -1px 0px rgba(0,0,0,0.02)',
        display: 'flex',
        flexDirection: 'column',
        padding: '0 24px',
      }}
    >
      <PlacesAutocomplete
        value={searchText}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: 48,
                alignItems: 'center',
              }}
            >
              <BackArrowIcon
                height={20}
                width={20}
                pathColor="#98A1A9"
                onClick={() => {
                  history.goBack();
                }}
                style={{ marginRight: 18 }}
              />
              <SearchIcon pathColor="#98A1A9" height={20} width={20} />
              <input
                {...getInputProps({
                  placeholder: 'Chargestation ID or address',
                  className: 'location-search-input',
                  style: {
                    backgroundColor: 'white',
                    borderWidth: 0,
                    borderRadius: 6,
                    padding: '0 16px',
                    width: '100%',
                  },
                })}
                // onFocus={onFocus}
                // onBlur={onBlur}
              />
              {
                <CloseOutlineIcon
                  height={30}
                  width={30}
                  style={{
                    visibility: searchText ? 'visible' : 'hidden',
                  }}
                  onClick={(e) => {
                    setSearchText('');
                    onClear();
                    e.stopPropagation();
                  }}
                />
              }
            </div>
            <div
              className="autocomplete-dropdown-container"
              style={{
                zIndex: 2000,
                width: '91%',
                maxWidth: 500,
                position: 'relative',
                marginLeft: 1,
              }}
            >
              {loading && <div>Loading...</div>}
              {!loading &&
                searchText.length > 0 &&
                chargeStations
                  .filter((eachChagestation) =>
                    eachChagestation.static_endpoint
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  )
                  .map((eachChargestation) => (
                    <div
                      style={{
                        backgroundColor: '#ffffff',
                        cursor: 'pointer',
                        padding: 8,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                      onClick={() => {
                        setSearchText(
                          `${eachChargestation.static_endpoint} - ${eachChargestation.location.address.streetAndNumber}`
                        );
                        handleSelect(eachChargestation);
                      }}
                    >
                      <div
                        style={{
                          width: 20,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <FlashIcon
                          height={12}
                          width={12}
                          pathColor={DEFAULT_GREY}
                        />
                      </div>
                      <div style={{ flex: 1, overflow: 'hidden' }}>
                        <Text
                          style={{
                            marginLeft: 8,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                          size={16}
                        >
                          {eachChargestation.static_endpoint} -{' '}
                          {eachChargestation.location.address.streetAndNumber}
                        </Text>
                      </div>
                    </div>
                  ))}
              {!loading &&
                suggestions.map((suggestion, index) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  return (
                    <>
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style: {
                            backgroundColor: '#ffffff',
                            cursor: 'pointer',
                            padding: 4,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          },
                        })}
                      >
                        <div
                          style={{
                            width: 20,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <LocationIcon
                            height={12}
                            width={12}
                            pathColor={DEFAULT_GREY}
                          />
                        </div>
                        <div style={{ flex: 1, overflow: 'hidden' }}>
                          <Text
                            style={{
                              marginLeft: 8,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                            size={16}
                          >
                            {suggestion.description}
                          </Text>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

const Maps = ({ organization, user }) => {
  const history = useHistory();
  const location = useLocation();

  const [cookies, setCookies] = useCookies();
  const [currentLocation, setCurrentLocation] = useState(
    organization?.driver_application?.default_location?.coordinates
      ? {
          lat:
            organization?.driver_application?.default_location
              ?.coordinates[1] ?? DEFAULT_LOCATION.lat,
          lng:
            organization?.driver_application?.default_location
              ?.coordinates[0] ?? DEFAULT_LOCATION.lng,
        }
      : DEFAULT_LOCATION
  );
  const [mapZoomLevel, setMapZoomLevel] = useState(13);
  const [chargeStations, setChargeStations] = useState([]);
  const [selectedChargeStation, setSelectedChargeStation] = useState({});
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [clusterData, setClusterData] = useState([]);

  useEffect(() => {
    init();
    updateLocationInCookie('/maps', cookies, setCookies);
    const raw_query = window.location.search.substring(1);
    const query = qs.parse(raw_query);

    if (query.search) {
      setSearch(query.search);
    }
    if (location.state) {
      setCurrentLocation(location.state.latLng);
    }
  }, []);

  const init = async () => {
    try {
      const response = await api.get(
        null,
        `v1/public/chargestations?paginate_limit=1000`
      );
      let lastCoordinates = DEFAULT_LOCATION;

      let data = [];

      ChargestationService.filterChargestations(response.result).map(
        (eachChargestation) => {
          data.push({
            type: 'Chargestation',
            properties: eachChargestation,
            geometry: {
              type: 'Point',
              coordinates: eachChargestation.geoLocation.coordinates,
            },
          });
          lastCoordinates = {
            lat: eachChargestation.geoLocation.coordinates[1],
            lng: eachChargestation.geoLocation.coordinates[0],
          };
        }
      );

      // setCurrentLocation(lastCoordinates);
      setClusterData(data);
      setChargeStations(response.result);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  //TODO use calculated distance to show nearby CS
  const calculateDistanceForCurrentLocation = (lat, lng) => {
    const _chargestations = _.cloneDeep(chargeStations);
    _chargestations.map((eachChagestation) => {
      let _lat = eachChagestation.location.coordinates.latitude;
      let _lng = eachChagestation.location.coordinates.longitude;
      let R = 6371; // radius of earth in km

      let dLat = rad(_lat - lat);
      let dLong = rad(_lng - lng);
      let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(lat)) *
          Math.cos(rad(lat)) *
          Math.sin(dLong / 2) *
          Math.sin(dLong / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      eachChagestation.distance = d;
    });
  };

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        setCurrentLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        // calculateDistanceForCurrentLocation(
        //   position.coords.latitude,
        //   position.coords.longitude
        // );
      },
      function (error) {
        toast.info('Location permission denied');
      }
    );

    try {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then(function (result) {
          if (result.state === 'granted' || result.state === 'prompt') {
          } else {
            toast.info('Location permission denied');
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  const searchChargestation = (searchResult) => {
    if (searchResult.static_endpoint) {
      setSelectedChargeStation(searchResult);
      setCurrentLocation({
        lat: searchResult.location.coordinates.latitude,
        lng: searchResult.location.coordinates.longitude,
      });
      setMapZoomLevel(19);
    } else {
      geocodeByAddress(searchResult)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => {
          setCurrentLocation(latLng);
        })
        .catch((error) => console.error('Error', error));
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <ToastContainer
        position="bottom-center"
        hideProgressBar
        autoClose={2500}
        transition={ToastZoomTransition()}
        closeButton={false}
      />
      <DetectSession
        cookies={cookies}
        setCookies={setCookies}
        updateCookie={updateCookie}
      />
      <DetectLowBalance user={user} organization={organization} />
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapWithChargestations
          defaultCurrentLocation={currentLocation}
          defaultMapZoomLevel={mapZoomLevel}
          defaultBounds={null}
          clusterData={clusterData}
          setSelectedChargeStation={setSelectedChargeStation}
          organization={organization}
        />
      </div>
      <SearchBox
        address={search}
        handleSelect={searchChargestation}
        chargeStations={chargeStations}
        onClear={() => {
          setSelectedChargeStation({});
        }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: 20,
          right: 20,
          backgroundColor: 'white',
          padding: 8,
          borderRadius: 32,
          cursor: 'pointer',
          boxShadow: '0px 3px 9px rgba(0, 0, 0, 0.17);',
        }}
        onClick={() => {
          getCurrentLocation();
        }}
      >
        <IoMdLocate
          size={'1.5rem'}
          color={getOrganizationColor(organization)}
        />
      </div>
      {!_.isEmpty(selectedChargeStation) && (
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            backgroundColor: 'white',
            padding: 8,
            minHeight: '40%',
            width: '100%',
          }}
        >
          <div
            style={{
              borderWidth: 2,
              width: '50%',
              marginLeft: '25%',
              borderRadius: 12,
              borderStyle: 'solid',
              borderColor: '#98A1A9',
              backgroundColor: '#98A1A9',
              opacity: 0.4,
            }}
          ></div>
          <div className="navigation-details">
            <IoLocationSharp className="location-logo" color="#98A1A9" />

            <img className="location-logo" src={LocationIcon} />
            <div className="location">
              <Text weight={700} size={16}>
                {selectedChargeStation?.location?.address?.streetAndNumber}
              </Text>
              <Text weight={700} size={16}>
                {selectedChargeStation?.location?.address?.city}
                {selectedChargeStation?.location?.address?.state ? ', ' : ''}
                {selectedChargeStation?.location?.address?.state}
              </Text>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                cursor: 'pointer',
              }}
              onClick={() => {
                const address = encodeURIComponent(
                  selectedChargeStation?.location?.address?.streetAndNumber +
                    ' ' +
                    selectedChargeStation?.location?.address?.city
                );
                window.open(
                  `http://maps.google.com/maps?daddr=${address}`,
                  '_blank'
                );
              }}
            >
              <NavigationIcon
                className="navigation-icon"
                pathColor={getOrganizationColor(organization)}
              />
              <Text
                weight={600}
                size={12}
                color={getOrganizationColor(organization)}
              >
                Navigate
              </Text>
            </div>
          </div>
          <div className="page-details">
            <IoLinkOutline style={{ marginRight: 6 }} color="#98A1A9" />

            <div className="page-url">
              <Text weight={200} size={14} opacity={0.5}>
                {organization.custom_domain}/
              </Text>
              <Text weight={600} size={14}>
                {selectedChargeStation &&
                  punycode.toUnicode(selectedChargeStation.static_endpoint)}
              </Text>
            </div>
            <img
              className="copy-icon"
              src={CopyIcon}
              onClick={(e) => {
                navigator.clipboard
                  .writeText(
                    `https://${organization.custom_domain}/${selectedChargeStation.static_endpoint}`
                  )
                  .then(() => {
                    toast.info('URL is copied to your clipboard');
                  })
                  .catch(() => {
                    toast.info('Error occured');
                  });
              }}
            />
          </div>
          {selectedChargeStation.connectors
            ?.filter((eachConnector) => eachConnector.rate)
            .map((eachConnector) => {
              //Ignoring Location Control Rate
              const minimumAmount = getMinimumAmount(eachConnector.rate);
              let minimumAmountText = '';

              if (minimumAmount.amount > 0) {
                minimumAmountText = `${convertCurrencyFromText(
                  eachConnector?.rate?.currency ?? organization?.stripe_currency
                )} ${minimumAmount?.amount} ${minimumAmount?.type}`;
              }

              return (
                <ConnectorCard
                  connector={eachConnector}
                  organizationColor={getOrganizationColor(organization)}
                  isSelected={false}
                  onClick={() => {}}
                  chargestation={selectedChargeStation}
                  minAmount={minimumAmountText}
                  rate={eachConnector.rate}
                />
              );
            })}
          <div style={{ margin: 4, padding: '8px 16px' }}>
            <button
              style={{
                backgroundColor: organization?.theme?.colors?.primary,
                borderWidth: 0,
                borderRadius: 6,
                width: '100%',
                padding: 16,
              }}
              onClick={() => {
                history.push(`/${selectedChargeStation.static_endpoint}`);
              }}
            >
              <Text
                size={16}
                weight={700}
                color="white"
                style={{ letterSpacing: '0.07em' }}
              >
                Station Details
              </Text>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  user: state.user,
});

export default connect(mapStateToProps)(Maps);
