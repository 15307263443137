import React from 'react';
import { Text } from 'components';
import { convertCurrencyFromText, getRatesWithType } from 'utils/helper';

export const RateLayout = () => {
  return <div></div>;
};

const RatesScheduleContainer = ({ rate, organizationColor }) => {
  if (rate?.type !== 'schedule') return null;

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: 2,
          borderBottom: '1px solid #AAA',
          marginBottom: 16,
          paddingBottom: 8,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: 8,
          }}
        >
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Text>Minimum amount</Text>
              <Text>
                {convertCurrencyFromText(rate?.currency)}{' '}
                {rate?.min_price?.incl_vat?.toFixed(2)}
              </Text>
            </div>
          </div>
        </div>
      </div>

      {rate?.layout?.map((eachLayout) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: 4,
            borderBottom: '1px solid #AAA',
            marginBottom: 16,
            paddingBottom: 8,
          }}
        >
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {eachLayout?.target?.day_of_week?.map((eachDayOfWeek) => (
              <Text
                size={10}
                style={{
                  margin: 2,
                  padding: 8,
                  border: `1px solid ${organizationColor}`,
                  borderRadius: 16,
                  width: 40,
                  textAlign: 'center',
                }}
              >
                {eachDayOfWeek.substring(0, 3)}
              </Text>
            ))}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: 8,
            }}
          >
            <div style={{ flex: 1, textAlign: 'center' }}>
              <Text weight={600}>
                {eachLayout?.target?.start_time}&nbsp;-&nbsp;
                {eachLayout?.target?.end_time}
              </Text>
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {getRatesWithType(eachLayout.rate, true).map((eachText) => (
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Text>{eachText.type}</Text>
                  <Text>{eachText.text}</Text>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: 2,
          borderBottom: '1px solid #AAA',
          marginBottom: 16,
          paddingBottom: 8,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: 8,
          }}
        >
          <div style={{ flex: 1, textAlign: 'center' }}>
            <Text weight={600}>Rest of the Time</Text>
          </div>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {getRatesWithType(rate?.default_rate, true).map((eachText) => (
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Text>{eachText.type}</Text>
                <Text>{eachText.text}</Text>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatesScheduleContainer;
