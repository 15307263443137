import React, { useEffect, useState } from 'react';
import {
  IoFlashSharp,
  IoTimeSharp,
  IoCloudSharp,
  IoCashSharp,
} from 'react-icons/io5';

import { hhmmss } from 'utils/helper';

const CO2_SAVED_PER_KWH = 0.762310001;

const TransactionStatsCard = ({ title, data, averageData, icon }) => {
  return (
    <div
      style={{
        minWidth: 150,
        flex: 1,
        height: 150,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        border: '1px solid #DDD',
        borderRadius: 8,
        margin: 16,
        padding: 8,
        backgroundColor: 'white',
      }}
    >
      <span
        style={{
          fontSize: 14,
          fontWeight: 400,
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {icon ? icon : null} {title}
      </span>
      {averageData ? (
        <span
          style={{
            fontSize: 14,
            fontWeight: 400,
            textAlign: 'center',
            color: '#AAA',
          }}
        >
          Average
        </span>
      ) : (
        <span></span>
      )}
      <span style={{ fontSize: 28, fontWeight: 700, textAlign: 'center' }}>
        {data}
      </span>
      {averageData ? (
        <span
          style={{
            fontSize: 20,
            fontWeight: 500,
            textAlign: 'center',
            color: '#AAA',
          }}
        >
          {averageData}
        </span>
      ) : (
        <span></span>
      )}
    </div>
  );
};

const TransactionsStats = ({ data }) => {
  const [stats, setStats] = useState({});
  const [calculate, setCalculate] = useState(false);

  useEffect(() => {
    let totalCost = 0;
    let totalCostCurrency;
    let totalWattHoursConsumed = 0;
    let totalDuration = 0;

    data.forEach((eachData) => {
      totalCost += eachData?.cost?.amount ?? 0;
      totalCostCurrency = eachData?.cost?.currency?.toUpperCase();
      totalWattHoursConsumed +=
        (eachData?.metrics?.wattHoursConsumed ?? 0) / 1000;

      totalDuration += eachData?.metrics?.timeSpentCharging ?? 0;
    });

    setStats({
      totalCost: parseFloat(totalCost).toFixed(2),
      totalCostCurrency,
      totalWattHoursConsumed: parseFloat(totalWattHoursConsumed).toFixed(2),
      totalDuration,
    });
    setCalculate(true);
  }, [data]);

  if (!data || data.length === 0 || !calculate) {
    return null;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      {/* <TransactionStatsCard title="Transactions" data={data.length} /> */}
      <TransactionStatsCard
        title={`Cost (${stats.totalCostCurrency})`}
        data={`${stats.totalCost}`}
        averageData={
          data.length > 1
            ? `${parseFloat(stats.totalCost / data.length).toFixed(2)}
        `
            : null
        }
        icon={<IoCashSharp size={14} style={{ marginRight: 4 }} />}
      />

      <TransactionStatsCard
        title="Energy (kWh)"
        data={`${stats.totalWattHoursConsumed}`}
        averageData={
          data.length > 1
            ? `${parseFloat(stats.totalWattHoursConsumed / data.length).toFixed(
                2
              )}`
            : null
        }
        icon={<IoFlashSharp size={14} style={{ marginRight: 4 }} />}
      />

      <TransactionStatsCard
        title="Time"
        data={`${hhmmss(stats.totalDuration)}`}
        averageData={
          data.length > 1
            ? `${hhmmss(stats.totalDuration / data.length)}`
            : null
        }
        icon={<IoTimeSharp size={14} style={{ marginRight: 4 }} />}
      />

      <TransactionStatsCard
        title="CO2 Saved (kg)"
        data={parseFloat(
          CO2_SAVED_PER_KWH * stats.totalWattHoursConsumed
        ).toFixed(2)}
        averageData={
          data.length > 1
            ? parseFloat(
                (CO2_SAVED_PER_KWH * stats.totalWattHoursConsumed) / data.length
              ).toFixed(2)
            : null
        }
        icon={<IoCloudSharp size={14} style={{ marginRight: 4 }} />}
      />
    </div>
  );
};

export default TransactionsStats;
