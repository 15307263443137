import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { IoLocationOutline, IoNavigate } from 'react-icons/io5';

import { Text, ConnectorIcon, Loading, Root, NotFound } from 'components';
import { PRIMARY_COLOR, DEFAULT_GREY } from 'theme';
import { getQueryString } from 'utils/helper';
import api from 'utils/api';
import './favorite.css';

const Favorite = ({ organization, user, auth0User }) => {
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const [chargestations, setChargestations] = useState({});
  const [loading, setLoading] = useState(true);
  const [connectors, setConnectors] = useState({});
  const organizationColor = organization?.theme?.colors?.primary
    ? organization?.theme?.colors?.primary
    : PRIMARY_COLOR;

  useEffect(() => {
    fetchChargestations();
  }, []);

  // useEffect(() => {
  //   fetchConnectors();
  // }, [chargestations]);

  const fetchChargestations = async () => {
    let query = {
      include_location: true,
      organization: organization.id,
      paginate_limit: 1000,
    };
    let qs = `?${getQueryString(query)}`;

    const token = await getAccessTokenSilently();
    const res = await api.get(token, `v1/public/chargestations`, qs);

    let _chargestations = {};

    for (let chargestation of res.result) {
      _chargestations[chargestation._id] = chargestation;
    }

    setChargestations(_chargestations);
    setLoading(false);
  };

  const fetchConnectors = async () => {
    let _connectors = {};
    if (user.favorite_chargestations) {
      for (let chargestation of user.favorite_chargestations) {
        const token = await getAccessTokenSilently();
        const res = await api.get(
          token,
          `v1/chargestations/${chargestation}/connectors`
        );
        _connectors[chargestation] = res.result;
      }
    }

    setConnectors(_connectors);
  };

  if (loading) {
    return <Loading></Loading>;
  } else if (
    !user.favorite_chargestations ||
    user?.favorite_chargestations.length === 0
  ) {
    return (
      <Root
        organization={organization}
        user={user}
        auth0User={auth0User}
        title="Favorites"
        showSupportChat={false}
      >
        <NotFound text="No Records Found" />
      </Root>
    );
  } else {
    return (
      <Root
        organization={organization}
        user={user}
        auth0User={auth0User}
        title="Favorites"
        showSupportChat={false}
      >
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          {user?.favorite_chargestations?.map((eachChargestation) => (
            <div
              key={eachChargestation}
              style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
                padding: '12px 16px',
                borderBottom: '1px solid #DDD',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: '4px 0',
                }}
              >
                <div>
                  <Text size={16} color={DEFAULT_GREY}>
                    {organization?.custom_domain}/
                  </Text>
                  <Text size={16}>
                    {chargestations[eachChargestation]?.static_endpoint}
                  </Text>
                </div>
                {/* <IoStar size="1.2rem" color={organizationColor} /> */}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ flex: 3, display: 'flex', alignItems: 'center' }}>
                  <IoLocationOutline
                    size="1.5em"
                    color={DEFAULT_GREY}
                    style={{ marginLeft: -3, marginRight: 8 }}
                  />
                  <Text size={12} weight={400}>
                    {chargestations[eachChargestation]?.location?.address
                      ?.streetAndNumber
                      ? `${chargestations[eachChargestation]?.location?.address?.streetAndNumber}, `
                      : null}

                    {chargestations[eachChargestation]?.location?.address?.city
                      ? `${chargestations[eachChargestation]?.location?.address?.city}, `
                      : null}
                    {chargestations[eachChargestation]?.location?.address?.state
                      ? `${chargestations[eachChargestation]?.location?.address?.state}, `
                      : null}
                    {chargestations[eachChargestation]?.location?.address
                      ?.postalCode
                      ? `${chargestations[eachChargestation]?.location?.address?.postalCode}, `
                      : null}
                    {
                      chargestations[eachChargestation]?.location?.address
                        ?.country
                    }
                  </Text>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const address = encodeURIComponent(
                      chargestations[eachChargestation]?.location?.address
                        ?.streetAndNumber +
                        ' ' +
                        chargestations[eachChargestation]?.location?.address
                          ?.city +
                        ' ' +
                        chargestations[eachChargestation]?.location?.address
                          ?.postalCode
                    );
                    window.open(
                      `http://maps.google.com/maps?daddr=${address}`,
                      '_blank'
                    );
                  }}
                >
                  <IoNavigate color={organizationColor} />
                  &nbsp;
                  {/* <img src={NavigationIcon} /> */}
                  <Text weight={600} size={12} color={organizationColor}>
                    Navigate
                  </Text>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {chargestations[eachChargestation]?.connectors?.map(
                  (eachConnector) => (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          border: `1px solid ${organizationColor}`,
                          borderRadius: 16,
                          flex: 1,
                          height: 96,
                          alignItems: 'center',
                          margin: 16,
                          maxWidth: '40%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          history.push(
                            `/${chargestations[eachChargestation]?.static_endpoint}`
                          );
                        }}
                      >
                        <div>
                          <ConnectorIcon
                            type={eachConnector.type}
                            color={organizationColor}
                          />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: 16,
                          }}
                        >
                          <Text size={16}>{eachConnector.power} kW</Text>
                          <Text size={12} weight={400}>
                            {eachConnector.type}
                          </Text>
                        </div>
                      </div>
                    </>
                  )
                )}
              </div>
            </div>
          ))}
        </div>
      </Root>
    );
  }
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  user: state.user,
  status: state.status,
  Auth0User: state.Auth0User,
});
export default connect(mapStateToProps)(Favorite);
