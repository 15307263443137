import React from 'react';

import { Text } from 'components';

const DevelopmentWarningBanner = ({ show }) => {
  if (!show) {
    return null;
  }

  return (
    <div style={{ backgroundColor: 'rgba(255,0,0,0.5)', textAlign: 'center' }}>
      <Text color="white">In Development</Text>
    </div>
  );
};

export default DevelopmentWarningBanner;
