import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import {
  IoPersonCircleOutline,
  IoMailOpenOutline,
  IoCallOutline,
} from 'react-icons/io5';

import { Text, Root } from './../../components';
import { PRIMARY_COLOR } from './../../theme';

import './Profile.css';

const Profile = ({ organization, user, auth0User }) => {
  const history = useHistory();
  const organizationColor = organization?.theme?.colors?.primary
    ? organization?.theme?.colors?.primary
    : PRIMARY_COLOR;

  return (
    <Root
      organization={organization}
      user={user}
      auth0User={auth0User}
      title="Profile Details"
      showSupportChat={false}
    >
      <div className="profile-picture-container">
        <img
          className="profile-picture"
          src={
            auth0User?.picture ??
            'https://edrv-public.s3.eu-central-1.amazonaws.com/edrv-icon.jpg'
          }
          alt=""
        />
      </div>
      <div className="profile-detail-container">
        <IoPersonCircleOutline size="2em" />
        <div className="profile-detail-text-container">
          <Text size={12} weight={400}>
            Name
          </Text>
          <Text size={16} weight={600}>
            {user.firstname} {user.lastname}
          </Text>
        </div>
      </div>
      <div className="profile-detail-container">
        <IoMailOpenOutline size="2em" />
        <div className="profile-detail-text-container">
          <Text size={12} weight={400}>
            Email
          </Text>
          <Text size={16} weight={600}>
            {user.email ?? '-'}
          </Text>
        </div>
      </div>
      <div className="profile-detail-container">
        <IoCallOutline size="2em" />
        <div className="profile-detail-text-container">
          <Text size={12} weight={400}>
            Mobile Number
          </Text>
          <Text size={16} weight={600}>
            {user?.phone?.mobile ?? '-'}
          </Text>
        </div>
      </div>
      <div className="profile-edit-button-container">
        <Button
          className="action-button"
          style={{
            backgroundColor: organizationColor,
            borderColor: organizationColor,
          }}
          onClick={() => {
            history.push('profile/edit');
          }}
        >
          Edit
        </Button>
      </div>
      <br />
    </Root>
  );
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  status: state.status,
  user: state.user,
  auth0User: state.auth0User,
});

export default connect(mapStateToProps)(Profile);
