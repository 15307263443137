import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import store from './store';
import { CookiesProvider } from 'react-cookie';
import * as serviceWorker from './serviceWorker';
import { init as initApm } from '@elastic/apm-rum';
import { Auth0Provider } from '@auth0/auth0-react';

if (process.env.NODE_ENV == 'production') {
  const apm = initApm({
    serviceName: 'driver-app',
    serverUrl: process.env.REACT_APP_APM_SERVER_URL,
    environment: process.env.NODE_ENV,
    serviceVersion: '',
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      //organization={process.env.REACT_APP_AUTH0_ORGANIZATION}
      redirectUri={window.location.origin}
      cacheLocation="localstorage"
    >
      <CookiesProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </CookiesProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

if (window.Cypress) {
  window.store = store;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
