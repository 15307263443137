import { UPDATE_HISTORY_DATA_ACTION as UPDATE_HISTORY_DATA } from './../constants';

const initialState = [];

const history = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_HISTORY_DATA:
      return [...action.payload];
    default:
      return state;
  }
};

export default history;
