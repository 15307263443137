import React from 'react';

const CloseOutline = ({ width, height, pathColor, style, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="ionicon"
    viewBox="0 0 512 512"
    width={width}
    height={height}
    style={style}
    onClick={onClick}
  >
    <title>Close</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="32"
      d="M368 368L144 144M368 144L144 368"
    />
  </svg>
);

export default CloseOutline;
