import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IoChevronForwardSharp } from 'react-icons/io5';

import Text from '../Text/Text';

const Notification = ({
  organizationColor,
  message,
  isShowing,
  timeoutSeconds,
  hide,
  title,
  style,
  textColor = 'white',
  redirectTo = '',
}) => {
  const history = useHistory();

  useEffect(() => {
    if (isShowing) {
      setTimeout(() => {
        hide();
      }, timeoutSeconds * 1000);
    }
  }, [isShowing]);

  if (!isShowing) {
    return null;
  } else {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          zIndex: 9999,
          width: '96%',
          backgroundColor: organizationColor,
          margin: '2%',
          padding: 16,
          borderRadius: 8,
          ...style,
        }}
        onClick={() => {
          if (redirectTo) {
            history.push(redirectTo);
          }
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {title ? (
              <div>
                <Text color={textColor} size={20} weight={600}>
                  {title}
                </Text>
              </div>
            ) : null}
            <div>
              <Text color={textColor} size={16}>
                {message}
              </Text>
            </div>
          </div>
          {redirectTo ? (
            <IoChevronForwardSharp color="white" size="1.5rem" />
          ) : null}
        </div>
      </div>
    );
  }
};

export default Notification;
