import React, { useState } from 'react';
import { Text } from 'components';
import { IoChevronUpOutline, IoChevronDownOutline } from 'react-icons/io5';

import './Session.css';

const SupportInformation = ({ show }) => {
  const [maxHeight, setMaxHeight] = useState(72);

  if (!show) return null;

  return (
    <div
      className="session-information"
      style={{ maxHeight }}
      onClick={() => {
        maxHeight == 72 ? setMaxHeight(200) : setMaxHeight(72);
      }}
    >
      <div style={{ flex: 9, margin: '14px 0' }}>
        <Text weight={400} size={12}>
          If you are unable to start a charging session within 5 minutes of
          pressing the start charging button, your pre-pay amount will be
          refunded to you and you will have to pre-authorize again. For security
          reasons, stop charging is only permitted from the same mobile device
          that started the transaction. You can always stop charging from your
          vehicle and drive away.
        </Text>
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {maxHeight == 72 ? (
          <IoChevronDownOutline size={24} color="#98A1A9" />
        ) : (
          <IoChevronUpOutline size={24} color="#98A1A9" />
        )}
      </div>
    </div>
  );
};

export default SupportInformation;
