import React from 'react';

const Type2Icon = ({ width, height, pathColor }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 36 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 29C26.3645 29 33 22.429 33 14.5C33 9.63217 32.4184 7.23609 30.9148 5.78251C29.3345 4.25473 25.9355 3 18 3C10.0645 3 6.66548 4.25473 5.08516 5.78251C3.58159 7.23609 3 9.63217 3 14.5C3 22.429 9.6355 29 18 29ZM18 32C27.9411 32 36 24.165 36 14.5C36 4.83502 34 0 18 0C2 0 0 4.83502 0 14.5C0 24.165 8.05887 32 18 32Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.3483 9.07627C15.3483 10.4345 14.2473 11.5356 12.889 11.5356C11.5308 11.5356 10.4297 10.4345 10.4297 9.07627C10.4297 7.71802 11.5308 6.61694 12.889 6.61694C14.2473 6.61694 15.3483 7.71802 15.3483 9.07627ZM11.7445 9.07627C11.7445 9.70835 12.2569 10.2208 12.889 10.2208C13.5211 10.2208 14.0335 9.70835 14.0335 9.07627C14.0335 8.44418 13.5211 7.93177 12.889 7.93177C12.2569 7.93177 11.7445 8.44418 11.7445 9.07627Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.569 9.07627C25.569 10.4345 24.468 11.5356 23.1097 11.5356C21.7515 11.5356 20.6504 10.4345 20.6504 9.07627C20.6504 7.71802 21.7515 6.61694 23.1097 6.61694C24.468 6.61694 25.569 7.71802 25.569 9.07627ZM21.9652 9.07627C21.9652 9.70835 22.4776 10.2208 23.1097 10.2208C23.7418 10.2208 24.2542 9.70835 24.2542 9.07627C24.2542 8.44418 23.7418 7.93177 23.1097 7.93177C22.4776 7.93177 21.9652 8.44418 21.9652 9.07627Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.69538 18.195C10.6565 18.195 12.2462 16.6052 12.2462 14.6441C12.2462 12.683 10.6565 11.0933 8.69538 11.0933C6.7343 11.0933 5.14453 12.683 5.14453 14.6441C5.14453 16.6052 6.7343 18.195 8.69538 18.195ZM8.69538 16.4322C7.70782 16.4322 6.90724 15.6317 6.90724 14.6441C6.90724 13.6565 7.70782 12.856 8.69538 12.856C9.68294 12.856 10.4835 13.6565 10.4835 14.6441C10.4835 15.6317 9.68294 16.4322 8.69538 16.4322Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.8556 14.6441C30.8556 16.6052 29.2658 18.195 27.3048 18.195C25.3437 18.195 23.7539 16.6052 23.7539 14.6441C23.7539 12.683 25.3437 11.0933 27.3048 11.0933C29.2658 11.0933 30.8556 12.683 30.8556 14.6441ZM25.5166 14.6441C25.5166 15.6317 26.3172 16.4322 27.3048 16.4322C28.2923 16.4322 29.0929 15.6317 29.0929 14.6441C29.0929 13.6565 28.2923 12.856 27.3048 12.856C26.3172 12.856 25.5166 13.6565 25.5166 14.6441Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.0508 25.6017C16.0119 25.6017 17.6017 24.0119 17.6017 22.0508C17.6017 20.0898 16.0119 18.5 14.0508 18.5C12.0898 18.5 10.5 20.0898 10.5 22.0508C10.5 24.0119 12.0898 25.6017 14.0508 25.6017ZM14.0508 23.839C13.0633 23.839 12.2627 23.0384 12.2627 22.0508C12.2627 21.0633 13.0633 20.2627 14.0508 20.2627C15.0384 20.2627 15.839 21.0633 15.839 22.0508C15.839 23.0384 15.0384 23.839 14.0508 23.839Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.6017 22.0508C25.6017 24.0119 24.0119 25.6017 22.0508 25.6017C20.0898 25.6017 18.5 24.0119 18.5 22.0508C18.5 20.0898 20.0898 18.5 22.0508 18.5C24.0119 18.5 25.6017 20.0898 25.6017 22.0508ZM20.2627 22.0508C20.2627 23.0384 21.0633 23.839 22.0508 23.839C23.0384 23.839 23.839 23.0384 23.839 22.0508C23.839 21.0633 23.0384 20.2627 22.0508 20.2627C21.0633 20.2627 20.2627 21.0633 20.2627 22.0508Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.1533 14.644C22.1533 16.9365 20.2949 18.7949 18.0024 18.7949C15.71 18.7949 13.8516 16.9365 13.8516 14.644C13.8516 12.3516 15.71 10.4932 18.0024 10.4932C20.2949 10.4932 22.1533 12.3516 22.1533 14.644ZM20.1533 14.644C20.1533 15.8319 19.1903 16.7949 18.0024 16.7949C16.8145 16.7949 15.8516 15.8319 15.8516 14.644C15.8516 13.4561 16.8145 12.4932 18.0024 12.4932C19.1903 12.4932 20.1533 13.4561 20.1533 14.644Z"
      fill={pathColor}
    />
  </svg>
);

export default Type2Icon;
