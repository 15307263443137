import React from 'react';

const BxTimeFive = ({ width, height, pathColor }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <path
      d="M12.0002 2C6.4862 2 2.0002 6.486 2.0002 12C2.0002 17.514 6.4862 22 12.0002 22C17.5142 22 22.0002 17.514 22.0002 12C22.0002 6.486 17.5142 2 12.0002 2ZM12.0002 20C7.5892 20 4.0002 16.411 4.0002 12C4.0002 7.589 7.5892 4 12.0002 4C16.4112 4 20.0002 7.589 20.0002 12C20.0002 16.411 16.4112 20 12.0002 20Z"
      fill={pathColor}
    />
    <path
      d="M13.0002 7H11.0002V12.414L14.2932 15.707L15.7072 14.293L13.0002 11.586V7Z"
      fill={pathColor}
    />
  </svg>
);

export default BxTimeFive;
