import React, { useRef, useEffect, useState } from 'react';
import {
  MdBatteryCharging20,
  MdBatteryCharging50,
  MdBatteryCharging80,
  MdBatteryChargingFull,
} from 'react-icons/md';

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}

const ChargingBatteryIcon = ({ show }) => {
  const [blinkCount, setBlinkCount] = useState(0);

  useInterval(() => {
    setBlinkCount(blinkCount + 1 > 3 ? 0 : blinkCount + 1);
  }, 1000);

  if (!show) return null;

  if (blinkCount === 3) {
    return <MdBatteryChargingFull />;
  } else if (blinkCount === 2) {
    return <MdBatteryCharging80 />;
  } else if (blinkCount === 1) {
    return <MdBatteryCharging50 />;
  } else {
    return <MdBatteryCharging20 />;
  }
};

export default ChargingBatteryIcon;
