import React from 'react';

const Locate = ({ width, height, pathColor, style, onClick }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    style={style}
    onClick={onClick}
  >
    <path
      d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
      fill={pathColor}
    />
    <path
      d="M13 4.069V2H11V4.069C7.389 4.522 4.523 7.389 4.069 11H2V13H4.069C4.523 16.611 7.388 19.478 11 19.931V22H13V19.931C16.611 19.478 19.478 16.612 19.931 13H22V11H19.931C19.478 7.389 16.611 4.522 13 4.069ZM12 18C8.691 18 6 15.309 6 12C6 8.691 8.691 6 12 6C15.309 6 18 8.691 18 12C18 15.309 15.309 18 12 18Z"
      fill={pathColor}
    />
  </svg>
);

export default Locate;
