import React from 'react';

import Text from './../../Text/Text';

const FooterBranding = ({ color, show }) => {
  if (!show) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: 'rgba(52,65,74,0.08)',
        height: 60,
        padding: 18,
        textAlign: 'center',
      }}
    >
      <Text size={14} weight={400}>
        © {new Date().getUTCFullYear()}
      </Text>
      <Text size={14} weight={400} color={color}>
        {' '}
        edrv.io
      </Text>
    </div>
  );
};

export default FooterBranding;
