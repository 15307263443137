import { stringToBoolean } from './../utils/helper';

const showFooterBranding = (organization) => {
  return stringToBoolean(
    organization?.driver_application?.show_edrv_footer ??
      process.env?.REACT_APP_SHOW_FOOTER_BRANDING ??
      true
  );
};

const disableAccounts = (organization) => {
  return stringToBoolean(
    organization?.driver_application?.disable_accounts ??
      process.env?.REACT_APP_DISABLE_ACCOUNTS ??
      true
  );
};

const disableWallets = (organization) => {
  return stringToBoolean(
    organization?.driver_application?.disable_wallets ??
      process.env?.REACT_APP_DISABLE_WALLETS ??
      true
  );
};

const isDevelopment = () => {
  let flag = process.env?.REACT_APP_API_URL === 'https://api.edrv.io';

  return !flag;
};

const isTestMode = ({ test_mode }) => {
  return test_mode;
};

export {
  showFooterBranding,
  disableAccounts,
  disableWallets,
  isDevelopment,
  isTestMode,
};
