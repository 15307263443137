import React from 'react';

import { Text } from 'components';

const MaintenanceNotice = () => {
  const notice = process.env.REACT_APP_MAINTENANCE_NOTE;

  if (notice) {
    return (
      <div
        style={{
          backgroundColor: '#fffbe6',
          border: '1px solid #ffe58f',
          padding: 5,
          fontSize: 10,
        }}
      >
        <Text color="black">{notice}</Text>
      </div>
    );
  }
  return <></>;
};

export default MaintenanceNotice;
