import { UPDATE_USER_DATA_ACTION as UPDATE_USER_DATA } from './../constants';

const initialState = {};

const user = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_DATA:
      const userHasMobile =
        action.payload && action.payload.phone && action.payload.phone.mobile
          ? true
          : false;
      localStorage.setItem('user_has_mobile', userHasMobile);
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default user;
