import React from 'react';

const ChademoIcon = ({ width, height, pathColor }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.75827 10.9797C12.9373 10.9797 15.5165 13.559 15.5165 16.738C15.5165 19.917 12.9373 22.4963 9.75827 22.4963C6.57922 22.4963 4 19.917 4 16.738C4 13.559 6.57922 10.9797 9.75827 10.9797ZM7.47895 16.738C7.47895 17.9976 8.49865 19.0173 9.75827 19.0173C11.0179 19.0173 12.0376 17.9976 12.0376 16.738C12.0376 15.4784 11.0179 14.4587 9.75827 14.4587C8.49865 14.4587 7.47895 15.4784 7.47895 16.738Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.4835 16.7681C18.4835 13.589 21.0627 11.0098 24.2417 11.0098C27.4208 11.0098 29.97 13.559 30 16.7681C30 19.9471 27.4208 22.5263 24.2417 22.5263C21.0627 22.5263 18.4835 19.9471 18.4835 16.7681ZM21.9624 16.7381C21.9624 17.9977 22.9821 19.0174 24.2417 19.0174C25.5013 19.0174 26.521 17.9977 26.521 16.7381C26.521 15.4785 25.5013 14.4588 24.2417 14.4588C22.9821 14.4588 21.9624 15.4785 21.9624 16.7381Z"
      fill={pathColor}
    />
    <path
      d="M17 7.90133L19.0987 10L20 9.09867L17.9013 7L20 4.90133L19.0987 4L17 6.09867L14.9013 4L14 4.90133L16.0987 7L14 9.09867L14.9013 10L17 7.90133Z"
      fill={pathColor}
    />
    <path
      d="M17 27.9013L19.0987 30L20 29.0987L17.9013 27L20 24.9013L19.0987 24L17 26.0987L14.9013 24L14 24.9013L16.0987 27L14 29.0987L14.9013 30L17 27.9013Z"
      fill={pathColor}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 34C26.3879 34 34 26.3889 34 17C34 7.6111 26.3879 0 17 0C7.61205 0 0 7.6111 0 17C0 26.3889 7.61205 34 17 34ZM17 32.0571C25.316 32.0571 32.0571 25.3158 32.0571 17C32.0571 8.68416 25.316 1.94286 17 1.94286C8.68404 1.94286 1.94286 8.68416 1.94286 17C1.94286 25.3158 8.68404 32.0571 17 32.0571Z"
      fill={pathColor}
    />
  </svg>
);

export default ChademoIcon;
