import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
// import Auth0Lock from 'auth0-lock';
import { BackArrowIcon, Header, Footer } from '../../components';

import { updateAuth0UserData } from '../../actions/auth0User';
import { updateUserData } from './../../actions/user';
import { isAuthenticated } from '../../utils/helper';

class Signin extends React.Component {
  lock;

  constructor(props) {
    super(props);

    let isSafari =
      navigator.vendor.match(/apple/i) &&
      !navigator.userAgent.match(/crios/i) &&
      !navigator.userAgent.match(/fxios/i);

    // this.state = { loggedIn: isAuthenticated() };
    this.onAuthenticated = this.onAuthenticated.bind(this);
    this.state = {
      cookieSupported: true,
      isSafari,
    };

    // this.lock = new Auth0Lock(
    //   process.env.REACT_APP_AUTH0_CLIENT_ID,
    //   process.env.REACT_APP_AUTH0_DOMAIN,
    //   {
    //     auth: {
    //       responseType: 'token id_token',
    //       sso: false,
    //       audience: process.env.REACT_APP_AUTH0_AUDIENCE, //https://auth0.com/docs/libraries/lock/lock-configuration#audience-string-
    //       params: {
    //         state: this.props.organization.id,
    //       },
    //     },
    //     container: 'auth0-login-container',
    //     theme: {
    //       logo: props?.organization?.logo,
    //       primaryColor: props?.organization?.theme?.colors?.primary,
    //     },
    //     languageDictionary: {
    //       title: props?.organization?.name,
    //     },
    //     // https://community.auth0.com/t/additional-fields-in-sign-in-form/11418/4
    //     additionalSignUpFields: [
    //       {
    //         name: 'mobile',
    //         placeholder: '4155551212',
    //         // The following properties are optional
    //         icon:
    //           'https://edrv-public.s3.eu-central-1.amazonaws.com/call-outline.svg',
    //         validator: function (mobile_number) {
    //           let pattern = /^\d+$/;
    //           return {
    //             valid: mobile_number.length > 9 && pattern.test(mobile_number),
    //             hint: 'Please enter a valid phone number',
    //           };
    //         },
    //       },
    //     ],
    //   }
    // );
    this.onAuthenticated();
  }

  onAuthenticated() {
    // this.lock.on('authenticated', (authResult) => {
    //   let expiresAt = JSON.stringify(
    //     authResult.expiresIn * 1000 + new Date().getTime()
    //   );
    //   this.props.updateAuth0User(authResult.idTokenPayload);
    //   localStorage.setItem('access_token', authResult.accessToken);
    //   localStorage.setItem('id_token', authResult.idToken);
    //   localStorage.setItem('expires_at', expiresAt);
    //   this.props.updateUserData();
    //   this.props.history.push('/');
    // });
  }

  componentDidMount() {
    // Avoid showing Lock when hash is parsed.
    if (!isAuthenticated()) {
      if (!/access_token|id_token|error/.test(this.props.location.hash)) {
        // this.lock.show({
        //   initialScreen:
        //     this.props?.location?.state?.state === 'signin'
        //       ? 'login'
        //       : 'signUp',
        // });
      }
    }

    const receiveMessage = (evt) => {
      if (evt.data === 'MM:3PCunsupported') {
        this.setState({ ...this.state, cookieSupported: false });
      } else if (evt.data === 'MM:3PCsupported') {
        this.setState({ ...this.state, cookieSupported: true });
      }
    };
    window.addEventListener('message', receiveMessage, false);
  }

  render() {
    return !isAuthenticated() ? (
      this.state.cookieSupported ? (
        <div>
          <div
            style={{
              paddingTop: 16,
              paddingLeft: 8,
              cursor: 'pointer',
              position: 'absolute',
              zIndex: '10000000',
            }}
          >
            <BackArrowIcon
              height={18}
              width={18}
              pathColor="#98A1A9"
              onClick={() => {
                this.props.history.goBack();
              }}
            />
            <iframe
              src="https://mindmup.github.io/3rdpartycookiecheck/start.html"
              style={{ display: 'none' }}
            />
          </div>
          <div id="auth0-login-container"></div>
        </div>
      ) : (
        <>
          <Header
            organization={this.props.organization}
            user={{}}
            auth0User={{}}
          />
          <div
            style={{
              height: '85vh',
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
              justifyContent: 'center',
              margin: 8,
              padding: 16,
            }}
          >
            <span style={{ textAlign: 'center', fontWeight: 600 }}>
              To function optimally, {this.props.organization.name} requires
              additional settings to be enabled on your phone.
            </span>
            <br />
            <span style={{ textAlign: 'center' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span>
                  Please follow the introductions below to create or access your
                  EV Driver account. Once complete, please refresh your{' '}
                  {this.state.isSafari ? 'Safari' : 'Chrome'} browser page to
                  continue.
                </span>
                <br />
                <img
                  src={
                    this.state.isSafari
                      ? 'https://edrv-public.s3.eu-central-1.amazonaws.com/Driver+Account+Screens.png'
                      : 'https://edrv-public.s3.eu-central-1.amazonaws.com/Driver+Account+Screens-2.png'
                  }
                  width="100%"
                />
              </div>
            </span>
            {/* <a
              href="https://docs.edrv.io/docs/driver-accounts#blank-screen-issue-with-some-user-accounts"
              style={{ textAlign: 'center' }}
            >
              Guide
            </a> */}
            <iframe
              src="https://rsafaya-edrv.github.io/IIIPartyCookiesCheck/index.html"
              style={{ display: 'none' }}
            />
          </div>
          <Footer organization={this.props.organization} />
        </>
      )
    ) : (
      <Redirect
        to={{
          pathname: '/',
          state: { from: this.props.location },
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  organization: state.organization,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateAuth0User: (data) => dispatch(updateAuth0UserData(data)),
    updateUserData: () => dispatch(updateUserData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
