export const DEFAULT_LOGO =
  'https://edrv-public.s3.eu-central-1.amazonaws.com/edrv-icon.jpg';
export const INITIALIZE_ACTION = 'INITIALIZE';
export const UPDATE_STATUS_LOADING_ACTION = 'UPDATE_STATUS_LOADING';
export const UPDATE_USER_DATA_ACTION = 'UPDATE_USER_DATA';
export const UPDATE_AUTH0_USER_DATA_ACTION = 'UPDATE_AUTH0_USER_DATA';
export const UPDATE_HISTORY_DATA_ACTION = 'UPDATE_HISTORY_DATA';
export const UPDATE_LOCATIONS_DATA_ACTION = 'UPDATE_LOCATIONS_DATA';
export const UPDATE_FAVORITE_DATA_ACTION = 'UPDATE_FAVORITE_DATA';

export const CONNECTOR_STATUS = {
  AVAILABLE: 'Available',
  UNAVAILABLE: 'Unavailable',
  FAULTED: 'Faulted',
  PREPARING: 'Preparing',
  CHARGING: 'Charging',
  FINISHING: 'Finishing',
  SUSPENDEDEVSE: 'SuspendedEVSE',
  SUSPENDEDEV: 'SuspendedEV',
  RESERVED: 'Reserved',
};
export const TRANSACTION_STATUS = {
  STARTED: 'Started',
  ENDED: 'Ended',
};
export const DEFAULT_LOCATION = {
  lat: 52.336725,
  lng: 4.871674,
};

export const SESSION_TYPE = {
  AD_HOC: 'ad_hoc',
  USER: 'user',
  PAYMENT_METHOD: 'payment_method',
  CUSTOM_BALANCE_TRANSACTION: 'custom_balance_transaction',
};

export const SESSION_STATUS = {
  CREATED: 'created',
  STARTED: 'started',
  RUNNING: 'running',
  ENDED: 'ended',
  CANCELED: 'cancelled',
};

export const PAYMENT_STATUS = {
  REQUIRES_PAYMENT_METHOD: 'requires_payment_method',
};
